
import React from 'react'
import Modal from 'react-modal';
import { useMutation } from '@apollo/client';
import { DELETE_INVENTORY } from '../../mutations/inventory';
import { INVENTORIES } from '../../queries/Inventory';
import { toast } from 'react-toastify';
 
function DeleteInventory(props) {
    const { isOpen, setIsOpen, id, setDeleteInventoryModal } = props;

    Modal.setAppElement('#root');

    const [deleteInventory, { loading: loadingOrder, error: errorOrder, data: data }] = useMutation(DELETE_INVENTORY, {
        onCompleted:() => {
            setIsOpen(false);
            toast.success("Successfully Removed Inventory!");
        }
    });

    return (
        <Modal 
            isOpen={isOpen}
            contentLabel="Delete Inventory"
            onRequestClose={() => {setDeleteInventoryModal(false)}}
            className="delete-modal-overlay"
        >
            <div className="delete-modal">
                <div className="confirm-delete">Are you sure you want to delete this inventory?</div>
                <div className="desc-delete">This inventory will not be recoverable.</div>
                <div className="button primary error" onClick={() => {
                    deleteInventory({
                        variables: {
                            inventoryId: id
                        },
                        refetchQueries: [{ query: INVENTORIES, variables: { search_term: "", order: "id_DESC" }}]
                    })
                }}>Delete Inventory</div>
                <div className="cancel-delete" onClick={() => setDeleteInventoryModal(false)}>Cancel</div>
            </div>
        </Modal>
    )
}

export default DeleteInventory