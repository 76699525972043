
import React from 'react'

function Input(props) {
    const { name, form, errors, title, handleInputChange, inline, type, disabled } = props;

    return (
        <div className={"input-container" + (inline ? " inline" : "")}>
            {
                title && (
                    <div className={"input-title"}>
                        {title}
                    </div>
                )
            }
            <input 
                name={name}
                value={form[name]}
                onChange={handleInputChange}
                className={"input-box" + (errors[name] ? " error" : "")}
                type={type ? type : ""}
                disabled={disabled}
            />
        </div>
    )
}

export default Input