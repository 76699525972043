
import React, { useState } from 'react'
import Modal from 'react-modal';
import Input from '../input';
import Dropdown from '../dropdown';
import { VENDORS, VENDOR } from '../../queries/vendors';
import { useQuery, useMutation } from '@apollo/client';
import Dropzone from 'react-dropzone'
import axios from 'axios';
import { ADD_INVENTORY } from '../../mutations/inventory';
import { INVENTORIES } from '../../queries/Inventory';
import { toast } from 'react-toastify';

function AddInventory(props) {
    const { isOpen, setIsOpen } = props;

    const default_form = {
        name: "",
        sku: "",
        images: [],
        vendorName: "",
        email: "",
        phone: "",
        address1: "",
        address2: "",
        city: "",
        province: "",
        postal: "",
        vendorId: "",
        description: "",
        retailPrice: 0.0,
        costPrice: 0.0,
        location: "",
        quantity: 0
    }

    const [inventoryForm, setInventoryForm] = useState({
       ...default_form 
    });

    const [errors, setErrors] = useState({});
    const [errorMessages, setErrorMessages] = useState([]);
    const [vendorsList, setVendorsList] = useState([])
    const [loadingVendors, setLoadingVendors] = useState(true)

    const { loading, error, data } = useQuery(VENDORS, {
        variables: { search_term: "", order: "id_DESC" },
    });

    const { loading: loadingInventory, error: errorInventory, data: dataInventory, refetch: refetchInventory } = useQuery(VENDOR, {
        variables: { vendorId: inventoryForm.vendorId },
    });

    const [addInventory, { loading: loadingAddVentory, error: errorAddInventory, data: dataAddInventory }] = useMutation(ADD_INVENTORY, {
        onCompleted: () => {
            setIsOpen(false);
            toast.success("Successfully Added New Inventory!");
        },
        onError: (error) => {
            toast.error(error.message);
        },
    })

    if (!loading && data.vendors && loadingVendors) {
        setLoadingVendors(false);
        var vendors = []
        data.vendors.map((vendor) => {
            vendors.push({value: vendor.id, label: vendor.vendorName})
        })

        setVendorsList(vendors);
    }
    if (!loadingInventory && dataInventory && dataInventory.vendor && dataInventory.vendor.id === inventoryForm.vendorId && inventoryForm.vendorName !== dataInventory.vendor.vendorName) {
        setInventoryForm({
            ...inventoryForm,
            ...dataInventory.vendor,
        })
    }

    Modal.setAppElement('#root');

    const handleInputChange = (e) => {
        var name = e.target.name;
        var value = e.target.value;

        setInventoryForm({
            ...inventoryForm,
            [name]: value
        })

        setErrors({
            ...errors,
            [name]: false
        })
    }

    const handleDropdownInputChange = (value) => {
        if (value) {
            setInventoryForm({
                ...inventoryForm,
                vendorName: value
            })

            setErrors({
                ...errors,
                vendorName: false
            })
        }
    }

    const handleDropdownChange = ( value ) => {
        if (value) {
            if (value.__isNew__) {
                setInventoryForm({
                    ...inventoryForm,
                    vendorName: value.value
                })
            } else {
                setInventoryForm({
                    ...inventoryForm,
                    vendorId: value.value
                })
            }

            setErrors({
                ...errors,
                vendorName: false
            })
        } else {
            setInventoryForm({
                ...inventoryForm,
                vendorName: "",
                email: "",
                phone: "",
                address1: "",
                address2: "",
                city: "",
                province: "",
                postal: "",
                vendorId: "",
            })
        }

    }

    const submitInventory = () => {
        var required_fields = ['name', 'quantity', 'sku'];
        var error = false;
        var err_copy = errors;
        var messages = []

        required_fields.map((field) => {

            if (inventoryForm[field] === "") {
                err_copy[field] = true;

                if (! error) {
                    messages.push("Please ensure all mandatory fields are filled.")
                }

                error = true;
            }

            if (field === 'quantity' && ! Number.isInteger(parseInt(inventoryForm[field]))) {
                err_copy[field] = true;

                if (! error) {
                    messages.push("Please ensure quantity field is only numbers.")
                }

                error = true;
            }
        })

        if (!inventoryForm.vendorId && (! inventoryForm.vendorName || ! inventoryForm.phone || ! inventoryForm.email)) {
            error = true;
            setErrors({
                    ...errors,
                    vendorName: true
                })
            messages.push("A vendor must be selected or added in order to add an inventory item")
        }

        if (error) {
            setErrorMessages(messages)
            setErrors(err_copy)
        } else {
            var images = [];
            inventoryForm.images.map((img) => {
                images.push(img.url);
            })

            addInventory({
                variables: {
                    name: inventoryForm.name,
                    sku: inventoryForm.sku,
                    images,
                    vendorName: inventoryForm.vendorName,
                    vendorEmail: inventoryForm.email,
                    vendorPhone: inventoryForm.phone,
                    vendorAddress1: inventoryForm.address1,
                    vendorAddress2: inventoryForm.address2,
                    vendorCity: inventoryForm.city,
                    vendorProvince: inventoryForm.province,
                    vendorPostal: inventoryForm.postal,
                    vendorId: inventoryForm.vendorId,
                    description: inventoryForm.description,
                    location: inventoryForm.location,
                    quantity: parseInt(inventoryForm.quantity),
                    retailPrice: parseFloat(inventoryForm['retailPrice']),
                    costPrice: parseFloat(inventoryForm['costPrice']),
                },
                refetchQueries: [{ query: INVENTORIES, variables: { search_term: "", order: "id_DESC" } }]
            })
        }

    }

    const uploadImages = (acceptedFiles) => {
        acceptedFiles.map((image, index) => {
            var images = inventoryForm.images
            image.uploading = true;
            var image_index = images.length;
            images.push(image);

            setInventoryForm({
                ...inventoryForm,
                images
            })

            var url = `https://api.cloudinary.com/v1_1/abztest/upload`;
            const formData = new FormData();
            formData.append("file", image);
            formData.append("api_key", '364725172583177');
            formData.append("upload_preset", "ecgold"); 
            formData.append("folder", "ecgold"); 
            formData.append("timestamp", (Date.now() / 1000) | 0);
            axios.post(url, formData, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest"
                }
            })
            .then(response => {
                images = inventoryForm.images;
                images.splice(image_index, 1, {url: response.data.url, name: image.name, uploading: false});
                setInventoryForm({
                    ...inventoryForm,
                    images
                })
            })
            .catch(err => {
                console.log("There has been ERROR: " + err)

                images = inventoryForm.images;
                images = images.splice(image_index, 1);

                setInventoryForm({
                    ...inventoryForm,
                    images
                })
            });
        })
    }
    
    return (
        <Modal 
            isOpen={isOpen}
            contentLabel="Add New Inventory Form"
            onRequestClose={() => {setIsOpen(false)}}
        >
            <div className="modal">
                <div className="page-header add-inventory">
                    <div className="header-text">
                        Add inventory
                    </div>
                    <div className="header-desc">
                        <div className="page-header-desc">
                            <Input
                                inline={true}
                                form={inventoryForm}
                                name={"sku"}
                                handleInputChange={handleInputChange}
                                title={"SKU #"}
                                errors={errors}
                            />
                        </div>
                        <div className="page-header-close">
                            <div onClick={() => {setIsOpen(false)}}>
                                &#10005;
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page">
                    <div className="form-row">
                        <Input
                            form={inventoryForm}
                            name={"name"}
                            handleInputChange={handleInputChange}
                            title={"Product Name"}
                            errors={errors}
                        />

                        <Dropdown
                            form={inventoryForm}
                            name={"name"}
                            handleInputChange={handleDropdownInputChange}
                            handleChange={handleDropdownChange}
                            title={"Vendor Name"}
                            errors={errors}
                            options={vendorsList}
                            loading={loading}
                            type="vendorName"
                            value={"vendorId"}
                            isCreatable={true}
                        />
                    </div>
                    <div className="form-row">
                        <Input
                            form={inventoryForm}
                            name={"email"}
                            handleInputChange={handleInputChange}
                            title={"Vendor Email Address"}
                            errors={errors}
                            type={"email"}
                            disabled={inventoryForm.vendorId ? true : false}
                        />

                        <Input
                            form={inventoryForm}
                            name={"phone"}
                            handleInputChange={handleInputChange}
                            title={"Vendor Phone Number"}
                            errors={errors}
                            type={"tel"}
                            disabled={inventoryForm.vendorId ? true : false}
                        />
                    </div>
                    <div className="form-row">
                        <Input
                            form={inventoryForm}
                            name={"address1"}
                            handleInputChange={handleInputChange}
                            title={"Address Line 1"}
                            errors={errors}
                            disabled={inventoryForm.vendorId ? true : false}
                        />

                        <Input
                            form={inventoryForm}
                            name={"city"}
                            handleInputChange={handleInputChange}
                            title={"City"}
                            errors={errors}
                            disabled={inventoryForm.vendorId ? true : false}
                        />
                    </div>
                    <div className="form-row">
                        <Input
                            form={inventoryForm}
                            name={"address2"}
                            handleInputChange={handleInputChange}
                            title={"Address Line 2"}
                            errors={errors}
                            disabled={inventoryForm.vendorId ? true : false}
                        />

                        <Input
                            form={inventoryForm}
                            name={"postal"}
                            handleInputChange={handleInputChange}
                            title={"Postal Code"}
                            errors={errors}
                            disabled={inventoryForm.vendorId ? true : false}
                        />

                    </div>
                    <div className="form-row">
                        <Input
                            form={inventoryForm}
                            name={"province"}
                            handleInputChange={handleInputChange}
                            title={"Province"}
                            errors={errors}
                            disabled={inventoryForm.vendorId ? true : false}
                        />
                        
                        <Input
                            form={inventoryForm}
                            name={"quantity"}
                            handleInputChange={handleInputChange}
                            title={"Quantity in Stock"}
                            errors={errors}
                            type={"number"}
                        />
                    </div>
                    <div className="form-row">
                        <Input
                            form={inventoryForm}
                            name={"costPrice"}
                            handleInputChange={handleInputChange}
                            title={"Cost Price"}
                            errors={errors}
                            type={"number"}
                        />
                        
                        <Input
                            form={inventoryForm}
                            name={"retailPrice"}
                            handleInputChange={handleInputChange}
                            title={"Retail Price"}
                            errors={errors}
                            type={"number"}
                        />
                    </div>
                    <div className="form-row">
                        <div className="form-50">
                            <Dropzone onDrop={acceptedFiles => {
                                uploadImages(acceptedFiles)
                            }}>
                                {({getRootProps, getInputProps}) => (
                                    <div className="dropzone-container">
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <img src={require("../../images/EmptyImage.png")} className="EmptyImage" alt="EmpyImage" title="EmptyImage"/>
                                            <div className="drop-image-text">Drop your image here, or browse</div>
                                            <div className="drop-image-support">Supports JPEG, PNG</div>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                            <div className="upload-images-container">
                                {
                                    inventoryForm.images.map((image, index) => (
                                        image.uploading ?
                                            <div>
                                                <div>Uploading...</div>
                                                <div>{image.url}</div>
                                            </div>
                                        :
                                            <div className="image-container">
                                                <div className="image-delete" onClick={() => {
                                                    var images = inventoryForm.images;
                                                    images.splice(index, 1);
                                                    setInventoryForm({
                                                        ...inventoryForm,
                                                        images
                                                    })
                                                }}>&#10005;</div>
                                                <img src={image.url} className="image-square" alt={image.name}/>
                                            </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="form-50 full-size">
                            <Input
                                form={inventoryForm}
                                name={"location"}
                                handleInputChange={handleInputChange}
                                title={"Location"}
                                errors={errors}
                            />
                            <div className={"input-container"}>
                                <div className={"input-title"}>
                                    Description
                                </div>
                                <textarea 
                                    name={'description'}
                                    onChange={handleInputChange}
                                    className={"input-box" + (errors['description'] ? " error" : "")}
                                >{inventoryForm['description']}</textarea>
                            </div>
                        </div>
                    </div>
                    {
                        errorMessages.map((err) => (
                            <div className="form-row">
                                <div className="error-message">
                                    {err}
                                </div>
                            </div>
                        ))
                    }
                    <div className="form-row">
                        <button className="primary button large" onClick={submitInventory}>Submit</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AddInventory