import React, { useState, useRef, useEffect } from 'react'
import { useQuery } from '@apollo/client';
import Table from '../../components/table';
import { ORDERS } from '../../queries/Orders';
import AddOrder from '../../components/orders/AddOrder';
import EditPhase from '../../components/orders/EditPhase';
import ViewOrder from '../../components/orders/ViewOrder';
import moment from 'moment';
import phases from '../../utils/phases'

import './styles.scss';
import priorities from '../../utils/priorities';
import Loading from '../../components/loading/loading';

function Calendar() {
    const [searchTerm, setSearchTerm] = useState("");
    const [numDates, setNumDates] = useState(7);
    const [currentPixelSize, setPixelSize] = useState(125);
    const [startDate, setStartDate] = useState(moment().subtract(Math.floor(numDates / 2), 'days').format("MM-DD-YYYY"));
    const [endDate, setEndDate] = useState(moment().add(Math.floor(numDates / 2), 'days').format("MM-DD-YYYY"));
    const [sortBy, setSortBy] = useState("endDate_ASC")
    const [addOrderModelIsOpen,setAddOrderIsOpen] = useState(false);
    const [viewOrderModelIsOpen,setViewOrderIsOpen] = useState(false);
    const [EditPhaseModelIsOpen,setEditPhaseIsOpen] = useState(false);
    const [currentOrderId, setCurrentOrderId] = useState("");

    const { loading, error, data, refetch } = useQuery(ORDERS, {
        variables: { search_term: searchTerm, order: sortBy, startDate: startDate, endDate: endDate, types: "Calendar"},
        fetchPolicy: "network-only"
    });

    const ref = useRef(null);
    
    const returnPhaseIcon = (phase) => {
        return phase.name;
    }

    const returnPriorityColor = (priority) => {
        return priorities[priority].color
    }

    useEffect(() => {
        //throw 'Parameter is not a number!';
        // Handler to call on window resize
        function handleResize() {
          // Set window width/height to state
          if (window.innerWidth < 500) {
            setNumDates(3);
            setStartDate(moment().subtract(3, 'days').format("MM-DD-YYYY"))
            setEndDate(moment().add(3, 'days').format("MM-DD-YYYY"))
          } else if (Window.innerWidth < 800) {
            setNumDates(5);
            setStartDate(moment().subtract(5, 'days').format("MM-DD-YYYY"))
            setEndDate(moment().add(5, 'days').format("MM-DD-YYYY"))
          }

          var pixel_size = ref.current && ref.current.offsetWidth ? ref.current.offsetWidth : currentPixelSize;

          if (pixel_size !== currentPixelSize) {
              setPixelSize(pixel_size);
          }
        }

        window.addEventListener("resize", handleResize);
    
        // Call handler right away so state gets updated with initial window size
        handleResize();
        
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
      }, [])    

    var tableData
    if (! data) {
        tableData = []
    } else {
        tableData = data.orders;
    }

    const moveCalendarBack = () => {
        setStartDate(moment(startDate, 'MM-DD-YYYY').clone().subtract(numDates, 'days').format("MM-DD-YYYY"))
        setEndDate(moment(endDate, 'MM-DD-YYYY').clone().subtract(numDates, 'days').format("MM-DD-YYYY"))
    }

    const moveCalendarForward = () => {
        setStartDate(moment(startDate, 'MM-DD-YYYY').clone().add(numDates, 'days').format("MM-DD-YYYY"))
        setEndDate(moment(endDate, 'MM-DD-YYYY').clone().add(numDates, 'days').format("MM-DD-YYYY"))
    }

    const renderCalendar = (tableData, loading, error) => {
        var dates = [];
        var max_pixels = currentPixelSize * numDates;
        var organized_data = {1: [], 2: [], 3: []}

        while (dates.length < numDates) {
            dates.push(
                moment(startDate, 'MM-DD-YYYY').add(dates.length, 'days')
            )
        }

        tableData.map((order) => {
            organized_data[order.priority].push(order);
        })

        return (
            <div className="calendar-main">
                <div className="calendar-dates">
                    <div className="calendar-date-color-container">
                    </div>
                    {
                        dates.map((date, index) => (
                            <div className="calendar-date" ref={index === 0 ? ref : null} style={date.startOf('day').isSame(moment().startOf('day')) ? {borderLeft: '3px solid red', borderRight: '3px solid red'} : {}}>
                                {date.format("dddd")}
                                <div>{date.format("DD")}</div>
                            </div>
                        ))
                    }
                </div>
                {
                    loading ? 
                        <div className="calendar">
                            <Loading />
                        </div>
                    :
                        error ?
                            <h1>Error, please refresh page</h1>
                        :
                            <div className="calendar">
                                <div className="calendar-main-container">
                                    <div className="calendar-lines">
                                        <div className="calendar-color-spacing"/> 
                                        {
                                            dates.map((date) => (
                                                <div className="calendar-date-box" style={date.startOf('day').isSame(moment().startOf('day')) ? {borderLeft: '3px solid red', borderRight: '3px solid red'} : {}}>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    {
                                        organized_data[3].length > 0 && (
                                            <div className="orders-priority-container" style={{borderColor: priorities[3].color}}>
                                                {
                                                    organized_data[3].map((order) => (
                                                        renderOrder(order, currentPixelSize, max_pixels)
                                                    ))
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        organized_data[2].length > 0 && (
                                            <div className="orders-priority-container" style={{borderColor: priorities[2].color}}>
                                                {
                                                    organized_data[2].map((order) => (
                                                        renderOrder(order, currentPixelSize, max_pixels)
                                                    ))
                                                }
                                            </div>
                                        )
                                    }
                                    {
                                        organized_data[1].length > 0 && (
                                            <div className="orders-priority-container" style={{borderColor: priorities[1].color}}>
                                                {
                                                    organized_data[1].map((order) => (
                                                        renderOrder(order, currentPixelSize, max_pixels)
                                                    ))
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                }
            </div>
        )
    }

    const renderOrder = (order, pixel_size, max_pixels) => {
        var start = startDate;
        var marginLeft = 0;
        var left_curved = true;
        var right_curved = true;
        let order_type_phases = order.primaryOrderType.phases;
        let completed_width;
        let maxed = false;

        if(moment(order.startDate).isAfter(startDate)) {
            start = moment(order.startDate);
            marginLeft = moment(order.startDate).diff(startDate, 'days');
        } else {
            left_curved = false;
        }

        if(! moment(order.endDate).isBefore(endDate)) {
            right_curved = false;
        }

        let width = (moment(order.endDate).diff(start, 'days') + 1) * pixel_size;
        marginLeft = marginLeft * pixel_size;
        if (width + marginLeft > max_pixels) {
            width = max_pixels - marginLeft;
        }

        let order_phases = [];
        order.logs.map((log) => {
            let days_after_beginning = moment(log.createdAt).diff(moment(order.startDate), 'days');

            //First phase wont show up if the order start date is not today, TODO: Contemplate this?
            //console.log(order.orderNumber, log.updatedPhase.phase, moment(log.createdAt), start)
            order_phases.push({
                phase: log.updatedPhase,
                completed: true,
                render: moment(log.createdAt).startOf('day').isSameOrAfter(start) && moment(log.createdAt).isSameOrBefore(moment(endDate, "MM-DD-YYYY")),
                days_after_beginning,
                date: moment(log.createdAt).startOf('day')
            })
        })

        if (moment(order.startDate).isSameOrBefore(startDate) && moment(order.endDate).isSameOrAfter(endDate)) {
            completed_width = max_pixels;
            maxed = true;
        } else {
            completed_width = order_phases[order_phases.length - 1].days_after_beginning * pixel_size
        }

        while(order_phases.length < order.primaryOrderType.phases.length) {
            var days_since_last_phase = ((order_type_phases[order_phases.length].percentage / 100) * order.primaryOrderType.recommendedNumDays)
            var days_after_beginning = order_phases[order_phases.length - 1].days_after_beginning + days_since_last_phase;
            
            var date = moment(order.startDate).add(days_after_beginning, 'days').startOf('day')
            order_phases.push({
                phase: order_type_phases[order_phases.length],
                completed: false,
                render: date.isSameOrAfter(start) && date.isSameOrBefore(endDate),
                days_after_beginning,
                days_since_last_phase,
                date
            })
        }

        var order_phases_filtered = [];

        order_phases.map((phase, i) => {
            if (phase.render) {
                var daysAfterBeg = phase.days_after_beginning;
                var num_days_before_today =moment(order.startDate).add(daysAfterBeg, 'days').diff(moment(), 'days')
                var afterEnding = moment(startDate, "MM-DD-YYYY").add(daysAfterBeg).isAfter(moment(order.endDate));
                var left;
                if (moment(startDate, 'MM-DD-YYYY').isAfter(order.startDate)) {
                    left = (phase.date).diff(moment(startDate, "MM-DD-YYYY"), 'days') * pixel_size + 10;
                } else {
                    left = (phase.date).diff(moment(order.startDate), 'days') * pixel_size + 10;
                }
                order_phases_filtered.push({
                    completed: phase.completed,
                    error: (num_days_before_today < 0 || afterEnding ? true : false) && !phase.completed,
                    marginLeft: left,
                    phase_colour: phase.phase.colour,
                    phase_number: phase.phase.phase
                })
            }
        })

        var container_styles = {width: width + "px", marginLeft: marginLeft + "px"};
        var extra_styles = {};
        if (left_curved) {
            extra_styles.borderTopLeftRadius = "15px";
            extra_styles.borderBottomLeftRadius = "15px";
        }

        if (right_curved) {
            extra_styles.borderTopRightRadius = "15px";
            extra_styles.borderBottomRightRadius = "15px";
        }

        return (
            <div className="order-container" style={{...container_styles, ...extra_styles}} onClick={() => openOrder(order)} key={order.id}>
                <div className="order-complete-mask" style={{width: completed_width, ...extra_styles}}></div>
                <div className="order-phases-number">Order # {order.orderNumber} - {order.customer.companyName}</div>
                <div className="order-phases-timeline-container" style={left_curved ? {width: width - 15 +"px", marginLeft: 15} : {width: width + "px"}}>
                    {
                        order_phases_filtered.map((phase) => {
                            var phase_colour = phase.phase_colour;

                            if (phase.completed) {
                                phase_colour = "green";
                            } else if (phase.error) {
                                phase_colour = "red";
                            }

                            return (
                                <div className="order-phase" style={{left: phase.marginLeft, backgroundColor: phase_colour}}>
                                {
                                    phase.completed ? 
                                        <span style={{maxHeight: "100%", maxWidth: "100%"}}>
                                            <svg
                                                className="prefix__icon prefix__icon-tabler prefix__icon-tabler-check"
                                                width='100%'
                                                height='100%'
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="white"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <path d="M0 0h24v24H0z" stroke="none" />
                                                <path d="M5 12l5 5L20 7" />
                                            </svg>
                                        </span>
                                    :
                                        phase.error ? 
                                            <span>!</span>
                                        :
                                            <span>{phase.phase_number}</span>
                                }
                            </div>
                            )
                        })
                    }
                </div>
                <div className="order-phases-date">{moment(order.endDate).format("MM/DD/YYYY")}</div>
            </div>
        )
    }

    const openOrder = (order) => {
        setCurrentOrderId(order.id);
        setViewOrderIsOpen(true);
    }

    return (
        <div className="page calendar-page">
            <div className="page-header">
                <div className="header-text">
                    Order Calendar
                </div>
                <div className="header-desc">
                    <div className="page-header-desc">
                        {
                            /*
                                        <input onChange={(e) => {
                  setSearchTerm(e.target.value); 
                  refetch({search_term: e.target.value, order: sortBy}
                )}} placeholder="search" value={searchTerm}/>
                            */
                        }
                    </div>
                    <div className="page-header-close">
                        <button className="primary button" onClick={() => setAddOrderIsOpen(true)}><span className="button-symbol">+</span>Add Order</button>
                    </div>
                </div>
            </div>
                <div className="calendar-container">
                    <div className="calendar-header">
                        <span className="calendar-arrow" onClick={moveCalendarBack}> <i class="fa fa-chevron-left"></i> </span>
                        <span className="calendar-dates"> {moment(startDate, "MM-DD-YYYY").format("MMM D")} - {moment(endDate, "MM-DD-YYYY").format("MMM D")} </span>
                        <span className="calendar-arrow" onClick={moveCalendarForward}> <i class="fa fa-chevron-right"></i> </span>
                    </div>
                    {
                            renderCalendar(tableData, loading, error)
                    }
                </div>

                {
                    addOrderModelIsOpen && (
                        <AddOrder isOpen={addOrderModelIsOpen} setIsOpen={setAddOrderIsOpen} order={sortBy} startDate={startDate} endDate={endDate}/>
                    )
                }
                {
                    viewOrderModelIsOpen && (
                        <ViewOrder isOpen={viewOrderModelIsOpen} setIsOpen={setViewOrderIsOpen} queryTypes={"Calendar"} setEditPhaseOpen={setEditPhaseIsOpen} setCurrentOrderId={setCurrentOrderId} id={currentOrderId} order={sortBy} startDate={startDate} endDate={endDate}/>
                    )
                }
                {
                    EditPhaseModelIsOpen && (
                        <EditPhase isOpen={EditPhaseModelIsOpen} setIsOpen={setEditPhaseIsOpen} setViewOrderOpen={setViewOrderIsOpen} id={currentOrderId} setCurrentOrderId={setCurrentOrderId} order={sortBy} startDate={startDate} endDate={endDate}/>
                    )
                }
        </div>
        
    )
}

export default Calendar
