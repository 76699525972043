import { gql } from '@apollo/client';

export const VENDORS = gql`
    query vendors($search_term: String!, $order: String!) {
        vendors(search_term: $search_term, order: $order) {
            id
            vendorName
        }
    }
`;

export const VENDOR = gql`
    query vendor($vendorId: String!) {
        vendor(vendorId: $vendorId) {
            id
            vendorName
            phone
            email
            address1
            address2
            city
            province
            postal
        }
    }
`;