import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./styles.scss"

function MobileHeader({setAddOrderIsOpen, addOrderModelIsOpen, mobileMenu, setMobileMenu, client }) {
    const location = useLocation();

    return (
        <div className={"mobile-menu" + (mobileMenu ? " mobile-active" : "")}>
            <div className={"mobile-opt"} onClick={() => {setMobileMenu(false); setAddOrderIsOpen(true)}}>New Order Submission</div>
            <Link to="/" className={"mobile-opt" + (location.pathname === "/orders" ? " selected" : "")} >Order Tracker</Link>
            <Link to="/calendar" className={"mobile-opt" + (location.pathname === "/" ? " selected" : "")}>Calendar</Link>
            <Link to="/inventory" className={"mobile-opt" + (location.pathname === "/inventory" ? " selected" : "")}>Inventory Tracker</Link>
            
            <a href="" className={"mobile-opt" } onClick={() => {
                setMobileMenu(false);
                localStorage.removeItem('authorization');
                client.resetStore();
            }}>
                Logout
            </a>
        </div>
    )
}

export default MobileHeader;
