import React from 'react';
import { useQuery, gql } from '@apollo/client';
import './styles/App.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Login from './components/login';
import Header from './components/header';
import OrdersTable from './pages/orderstable';
import InventoryTable from './pages/inventory';
import Calendar from './pages/calendar';
import { ToastContainer } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';
import MobileHeader from './components/mobileHeader';

const ME = gql`
  query Me {
    me {
        name
        email
    }
  }
`;

function App() {
    const { client, loading, error, data, refetch } = useQuery(ME);
    let checked = localStorage.getItem('authorization') && ! data && !error ? true : false ;
    const loggedIn = (data && data.me) || checked ? true : false;
    const user = (data && data.me) ? data.me : {}
    const [mobileMenu, setMobileMenu] = React.useState(false);
    const [addOrderModelIsOpen,setAddOrderIsOpen] = React.useState(false);
    
    return (      
        <Router>
            <MobileHeader addOrderModelIsOpen={addOrderModelIsOpen} setAddOrderIsOpen={setAddOrderIsOpen} mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} client={client}/>
            <div className={"App-Container" + (mobileMenu ? " mobile-active" : "")}>
              <Header client={client} loggedIn={loggedIn} user={user} mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} addOrderModelIsOpen={addOrderModelIsOpen} setAddOrderIsOpen={setAddOrderIsOpen}/>
              {
                loggedIn ? 
                  <div className="App">
                    <Switch>
                      <Route exact path="/" children={<OrdersTable />}  onChange={() => setMobileMenu(false)}/>
                      <Route exact path="/calendar" children={<Calendar/>}  onChange={() => setMobileMenu(false)}/>
                      <Route exact path="/inventory" children={<InventoryTable/>}  onChange={() => setMobileMenu(false)}/>
                      <Redirect to="/" />
                    </Switch>
                  </div>
                :
                <div className="App">
                  <Switch>
                    <Route exact path="/" children={<Login refetchLogin={() => {refetch(); checked = true;}} loadingLogin={loading && checked}/>} />
                    <Redirect to="/" />
                  </Switch>
                </div>
              }
            </div>

            <ToastContainer />
        </Router>
    );
}

export default App;
