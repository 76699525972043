
import React, { useState } from 'react'
import moment from 'moment'
import "./styles.scss"

function OrderCalendar({ startDate, endDate, updateDate }) {
    const [currDate, setDate] = useState(moment());

    const genCalendar = (date) => {
        var startWeek = date.clone().startOf('month').week();
        var endWeek = date.clone().endOf('month').week();

        let calendar = []
        if (startWeek > endWeek) {
            endWeek = 52 + endWeek
        }
        
        for(var week = startWeek; week <= endWeek; week++){
            var week_copy = week;
            var new_date = date.clone().week(week_copy);

            if (week > 53) {
                week_copy = week - 52;
                new_date.year(date.year() + 1);
            }

            calendar.push({
                week:week_copy,
                days:Array(7).fill(0).map((n, i) => new_date.clone().startOf('week').add(n + i, 'day'))
            })
        }

        var calendar_arr = []
        calendar.map((week) => {
            var week_arr = []

            week.days.map((day) =>{
                var classes = "day"
                if (moment(startDate, "MM-DD-YYYY").isSameOrBefore(day.format("MM-DD-YYYY")) && moment(endDate, "MM-DD-YYYY").isSameOrAfter(day.format("MM-DD-YYYY"))) {
                    classes += " order"
                }
                week_arr.push(
                    <td className={classes} onClick={() => updateDate(moment(day).format("MM-DD-YYYY"))}>{moment(day).format("D")}</td>
                )
            })

            calendar_arr.push(week_arr)
        })

        return (
            <table>
                <tr className="calendar-day-row">
                    {
                        Array(7).fill(0).map((n, i) => (
                            <td className="calendar-day-cell">{moment().day(i).format("dddd")}</td>
                        ))
                    }
                </tr>
                {calendar_arr.map((week) => {
                    return (
                        <tr>
                            {week}
                        </tr>
                    )

                })}
            </table>
        )
    }

    const moveDate = (amount) => {
        setDate(currDate.clone().add(amount, 'months'))
    }

    return (
        <div className="addorder-container">
            <div className="calendar-header">
                <span className="calendar-arrow" onClick={() => moveDate(-1)}> <i class="fa fa-chevron-left"></i> </span>
                <span className="calendar-dates"> {currDate.clone().startOf('month').startOf('week').format("MMM DD")} - {currDate.clone().endOf('month').endOf('week').format("MMM DD")} </span>
                <span className="calendar-arrow" onClick={() => moveDate(1)}> <i class="fa fa-chevron-right"></i> </span>
            </div>
            {genCalendar(currDate)}
        </div>
    )
}

export default OrderCalendar