
import React from 'react'
import moment from 'moment'
import phases from '../../utils/phases'

import "./styles.scss";
class PrintOrder extends React.Component {
    renderPhase = (logs) => {
        var orders = [];

        var last_phase = 1;

        logs.map((log) => {
            if (log.updatedPhase.phase >= last_phase) {
                last_phase = log.updatedPhase.phase + 1;
            }
            
            orders.push(
                <div className="order-phase-row">
                    <div className="order-phase-number" style={{backgroundColor: log.updatedPhase.colour}}>
                        <i class="fa fa-check" aria-hidden="true"></i>
                    </div>
                    <div className="order-phase-info">
                        <div className="order-phase-title">
                            {log.updatedPhase.name}
                        </div>
                        <div className="order-phase-info">
                            <span style={{fontWeight: "bold"}}>{log.editor.name}</span>  <span>{phases[log.updatedPhase.phase].desc}</span>
                        </div>
                        <div className="order-phase-date">
                            <i class="fa fa-clock-o" aria-hidden="true"></i> {moment(log.createdAt).fromNow()}
                        </div>
                    </div>
                </div>
            )
        })

        while (last_phase <= 5) {
            orders.push(
                <div className="order-phase-row">
                    <div className="order-phase-number" style={{backgroundColor: phases[last_phase].colour}}>
                        {last_phase}
                    </div>
                    <div className="order-phase-info">
                    </div>
                </div>
            )

            last_phase++;
        }

        return orders;
    }

    render() {
        const order  = this.props.data;

        return (
            <div className="modal print-order">
                <div className="print-header">
                    <div className="header-container">
                        <div className="header-title">Order #</div>
                        <div className="header-info">{order.orderNumber}</div>
                    </div>
                    <div className="header-container">
                        <div className="header-title">Web Order #</div>
                        <div className="header-info">{order.webOrderNumber}</div>
                    </div>
                    <div className="header-container">
                        <div className="header-title">Target Start Date</div>
                        <div className="header-info">{moment(order.startDate).format("MMM DD, YYYY")}</div>
                    </div>
                    <div className="header-container">
                        <div className="header-title">Due Date</div>
                        <div className="header-info highlighted">{moment(order.endDate).format("MMM DD, YYYY")}</div>
                    </div>
                </div>
                <div className="print-contact">
                    <div className="print-column">
                        <div className="print-contact-container">
                            <div className="contact-title">Company Name:</div>
                            <div className="contact-info">{order.customer.companyName || order.customer.customerName}</div>
                        </div>
                        <div className="print-contact-container">
                            <div className="contact-title">Email:</div>
                            <div className="contact-info">{order.customer.email || "N/A"}</div>
                        </div>
                        <div className="print-contact-container">
                            <div className="contact-title">Phone Number:</div>
                            <div className="contact-info">{order.customer.phone || "N/A"}</div>
                        </div>
                    </div>
                    <div className="print-column">
                        <div className="print-contact-container">
                            <div className="contact-title">Address:</div>
                            <div className="contact-info">{order.customer.address1} {order.customer.address2 && (<span>, {order.customer.address2}</span>)}</div>
                            <div className="contact-info" style={{marginTop: "2.5px"}}>{order.customer.city}, {order.customer.province}, {order.customer.postal}</div>
                        </div>
                        <div className="print-contact-container">
                            <div className="contact-title">Description:</div>
                            <div className="contact-info">{order.description || "N/A"}</div>
                        </div>
                    </div>
                </div>
                <div className="print-inventory-container">
                    <div className="print-main-header">Selected  Inventory:</div>
                    <div className="print-inventory-table">
                        <div className="print-inventory-header">
                            <div style={{width: "4%"}}>QTY</div>
                            <div style={{width: "4%"}}>B/O</div>
                            <div style={{width: "10%"}}>SKU</div>
                            <div style={{width: "22%"}}>ITEM</div>
                            <div style={{width: "22%"}}>DESC</div>
                            <div style={{width: "8%"}}>LOC</div>
                            <div style={{width: "20%"}}>Vendor</div>
                            <div style={{width: "10%"}}>Retail $</div>
                        </div>
                        {
                            order.orderInventory.length > 0 ?
                                order.orderInventory.map(inv => {
                                    var backOrder = inv.beforeOrderQuantity - inv.quantity < 0 ? Math.abs(inv.beforeOrderQuantity - inv.quantity) : 0;

                                    return  (
                                        <div className="print-inventory-row" style={backOrder > 0 ? {color: 'red'} : {color: 'grey'} }>
                                            <div style={{width: "4%"}}>{inv.quantity}</div>
                                            <div style={{width: "4%"}}>{backOrder}</div>
                                            <div style={{width: "10%"}}>{inv.inventory.sku}</div>
                                            <div style={{width: "22%"}}>{inv.inventory.name}</div>
                                            <div style={{width: "22%"}}>{inv.inventory.description}</div>
                                            <div style={{width: "8%"}}>{inv.inventory.location}</div>
                                            <div style={{width: "20%"}}>{inv.inventory.vendor.vendorName}</div>
                                            <div style={{width: "10%"}}>{inv.inventory.retailPrice}</div>
                                        </div>
                                    )
                                })
                            :
                                <div style={{textAlign: "center"}}>N/A</div>
                        }
                    </div>
                </div>
                <div className="print-main">
                    <div className="main-column">
                        <div className="print-main-header">Order Types:</div>
                        <ul className="print-main-types">
                            {
                                order.orderType.map(type => (
                                    <li>{type.name}</li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="main-column">
                        <div className="print-order-details">
                            <div className="order-details-col">
                                <div className="print-main-header">Comments:</div>
                                {
                                    order.logs.length  > 0 ?
                                        order.logs.map(log => {
                                            if (log.comments) {
                                                return (
                                                    <div className="print-main-comment"><span style={{fontWeight: "bold"}}>{log.editor.name} on {moment(log.createdAt).format("MMM DD, YYYY HH:MM")}:</span> {log.comments}</div>
                                                )
                                            }
                                        })
                                    :
                                        <span>N/A</span>
                                }
                            </div>
                            <div className="order-details-col">
                                <div className="print-main-header">Phases:</div>
                                {this.renderPhase(order.logs)}
                                <div className="phase-through-line" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PrintOrder