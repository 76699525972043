import React from 'react'
import { useTable } from 'react-table'

function Table({ columns, data, onHeaderPress, onRowPress, sort }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data,
    })

    const returnArrow = (column, sortBy) => {
      if (column.sortBy + "_ASC" === sortBy) {
        return (
          <span className="table-sort-arrow up">&#8963;</span>
        )
      } else if (column.sortBy + "_DESC" === sortBy) {
        return (
          <span className="table-sort-arrow down">&#8964;</span>
        )
      }
    }
  
    // Render the UI for your table
    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} onClick={() => onHeaderPress(column)} className={column.sortBy ? "sortable" : ""}><div style={{display: 'flex'}}>{column.render('Header')}{returnArrow(column, sort)}</div></th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} onClick={() => onRowPress(row)}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  export default Table;