import React, { useState } from 'react'
import { useQuery } from '@apollo/client';
import Table from '../../components/table';
import { ORDERS } from '../../queries/Orders';
import AddOrder from '../../components/orders/AddOrder';
import EditPhase from '../../components/orders/EditPhase';
import ViewOrder from '../../components/orders/ViewOrder';
import moment from 'moment';
import phases from '../../utils/phases'
import priorities from '../../utils/priorities';
import Loading from '../../components/loading/loading';

function OrdersTable() {
    const [searchTerm, setSearchTerm] = useState("")
    const [sortBy, setSortBy] = useState("id_DESC")
    const [orderTypes, setOrderTypes] = useState("Active");
    const [addOrderModelIsOpen,setAddOrderIsOpen] = React.useState(false);
    const [viewOrderModelIsOpen,setViewOrderIsOpen] = React.useState(false);
    const [EditPhaseModelIsOpen,setEditPhaseIsOpen] = React.useState(false);
    const [currentOrderId, setCurrentOrderId] = React.useState("");
    const { loading, error, data, refetch } = useQuery(ORDERS, {
        variables: { search_term: searchTerm, order: sortBy, types: orderTypes },
        fetchPolicy: "network-only"
    });

    const columns = React.useMemo(
        () => ([
        
            {
                Header: 'Order #',
                accessor: 'orderNumber',
                sortBy: "orderNumber"
            },
            {
                Header: 'Order Entered',
                accessor: 'createdAt',
                Cell: props => moment(props.value).format("MM/DD/YYYY"),
                sortBy: "createdAt"
            },
            {
                Header: 'Order Started',
                accessor: 'startDate',
                Cell: props => moment(props.value).format("MM/DD/YYYY"),
                sortBy: "startDate"
            },
            /*
            {
                Header: 'Order Type',
                accessor: 'orderType',
                Cell: props => props.value.map((type, index) => (<span>{index > 0 && (", ")}{type.name}</span>)),
                headerSort:'OrderType'
            },
            */
            {
                Header: 'Company Name',
                accessor: 'customer.companyName',
            },
            {
                Header: 'Delivery Date',
                accessor: 'endDate',
                Cell: props => moment(props.value).format("MM/DD/YYYY"),
                sortBy: "endDate"
            },
            {
                Header: 'Quantity',
                accessor: 'quantity',
                sortBy: "quantity"
            },
            {
                Header: 'Order Priority',
                accessor: 'priority',
                Cell: props => returnPriority(props.value),
                sortBy: "priority"
            },
            {
                Header: 'Amount Owed',
                accessor: 'amountOwed',
                sortBy: "amountOwed"
            },
            {
                Header: 'Status',
                accessor: 'phase',
                Cell: props => returnPhase(props.row.original, props.value),
                //sortBy: "phase"
            },
        ]),
        []
    )

    const returnPriority = (priority) => {
        return priorities[priority].title;
    }

    const returnPhase = (order, phase) => {
        if (order.status === "ACTIVE") {
            return (<div className="phase-box" style={{backgroundColor: phase.colour}}>{phase.name}</div>)
        } else {
            return (<div className="phase-box" style={{backgroundColor: "red"}}>Cancelled</div>)
        }
        
    }

    var tableData
    if (! data) {
        tableData = {orders: []}
    } else {
        tableData = data;
    }

    tableData = React.useMemo(() => tableData.orders)

    const SelectRow = (id) => {
        setCurrentOrderId(id);
        setViewOrderIsOpen(true);
    }

    const sortColumn = (column) => {
        var order = ["_ASC", "_DESC"]
        if (column.sortBy) {
            var sort = column.sortBy;
            if (sort + "_ASC" !== sortBy && sort + "_DESC" !== sortBy) {
                setSortBy(sort + "_ASC");
            } else if (sort + "_ASC" == sortBy) {
                setSortBy(sort + "_DESC");
            } else {
                setSortBy("id_DESC");
            }
        }
    }

    return (
        <div className="table-page">
            <div className="page-header mobile-hidden">
                <div className="header-text">
                    Order tracker
                </div>
                <div>
                    <button onClick={() => setAddOrderIsOpen(true)} className="button primary large">
                        <span className="button-symbol">+</span>Add Order</button>
                </div>
            </div>
            <div className="page-options mobile-header">
                <div className="searchbar-container">
                    <div className="searchbar">
                        <div className="searchbar-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                width="20" height="20"
                                viewBox="0 0 24 24"
                                style={{fill: "#000000"}}><path d="M 13.261719 14.867188 L 15.742188 17.347656 C 15.363281 18.070313 15.324219 18.789063 15.722656 19.1875 L 20.25 23.714844 C 20.820313 24.285156 22.0625 23.972656 23.015625 23.015625 C 23.972656 22.058594 24.285156 20.820313 23.714844 20.25 L 19.191406 15.722656 C 18.789063 15.324219 18.070313 15.363281 17.347656 15.738281 L 14.867188 13.261719 Z M 8.5 0 C 3.804688 0 0 3.804688 0 8.5 C 0 13.195313 3.804688 17 8.5 17 C 13.195313 17 17 13.195313 17 8.5 C 17 3.804688 13.195313 0 8.5 0 Z M 8.5 15 C 4.910156 15 2 12.089844 2 8.5 C 2 4.910156 4.910156 2 8.5 2 C 12.089844 2 15 4.910156 15 8.5 C 15 12.089844 12.089844 15 8.5 15 Z"></path></svg>
                        </div>
                        <input 
                            onChange={(e) => {
                                setSearchTerm(e.target.value); 
                                refetch({search_term: e.target.value, order: sortBy, types: orderTypes}
                            )}} 
                            placeholder="Search..." 
                            value={searchTerm}
                        />
                    </div>
                    <div className="searchbar-cancel" onClick={() => {
                        setSearchTerm(""); 
                        refetch({search_term: "", order: sortBy, types: orderTypes});
                    }}>
                        Cancel
                    </div>
                </div>
                <div className="order-types">
                    <div className={"order-type" + (orderTypes === "Active" ? " active" : "")} onClick={() => setOrderTypes("Active")}>
                        ACTIVE ORDERS
                    </div>
                    <div className={"order-type" + (orderTypes === "All" ? " active" : "")} onClick={() => setOrderTypes("All")}>
                        ALL ORDERS
                    </div>
                </div>
            </div>
                {
                    error ?
                      <h1>Error</h1>
                    :
                    <div className="table">
                        <Table columns={columns} data={tableData} sort={sortBy} onHeaderPress={sortColumn} onRowPress={(row) => {SelectRow(row.original.id);}}/>
                        {
                            loading && (
                                <div className="loading-mask">
                                    <Loading />
                                </div>
                            )
                        }
                    </div>
                      
                }
                {
                    addOrderModelIsOpen && (
                        <AddOrder isOpen={addOrderModelIsOpen} setIsOpen={setAddOrderIsOpen} order={sortBy} queryTypes={orderTypes}/>
                    )
                }
                {
                    viewOrderModelIsOpen && (
                        <ViewOrder isOpen={viewOrderModelIsOpen} setIsOpen={setViewOrderIsOpen} setEditPhaseOpen={setEditPhaseIsOpen} setCurrentOrderId={setCurrentOrderId} id={currentOrderId} orderTypes={orderTypes} order={sortBy}/>
                    )
                }
                {
                    EditPhaseModelIsOpen && (
                        <EditPhase isOpen={EditPhaseModelIsOpen} setIsOpen={setEditPhaseIsOpen} setViewOrderOpen={setViewOrderIsOpen} id={currentOrderId} setCurrentOrderId={setCurrentOrderId} order={sortBy} orderTypes={orderTypes}/>
                    )
                }
                {

                }
        </div>
        
    )
}

export default OrdersTable
