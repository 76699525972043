
import React from 'react'

const phases = { 
    1: {
        title: 'Order Entry',
        desc: 'has entered the order',
        numDays: 1,
        colour: 'teal'
    },
    2: {
        title: 'Artwork',
        desc: 'has started the order',
        numDays: 1,
        colour: 'lightpink'
    }, 
    3: {
        title: 'Client Approval',
        desc: <span>changed phase from artwork to <strong>Client Approval</strong></span>,
        numDays: 1,
        colour: 'purple'
    },
    4: {
        title: 'Production',
        desc: <span>changed phase from Client Approval to <strong>Production</strong></span>,
        numDays: 1,
        colour: 'orange'
    },
    5: {
        title: 'Complete',
        desc: <span>changed phase from Production to <strong>Complete</strong></span>,
        numDays: 1,
        colour: 'lightgreen'
    }
}

export default phases;