
import React from 'react'
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

function Dropdown(props) {
    const { name, form, errors, title, handleInputChange, inline, handleChange, options, type, loading, value, isMulti, isCreatable, formatOptionLabel } = props;

    return (
        <div className={"input-container" + (inline ? " inline" : "")}>
            {
                title && (
                    <div className={"input-title"}>
                        {title}
                    </div>
                )
            }
            {
                isCreatable ? 
                    <CreatableSelect
                        isClearable
                        name={name}
                        value={isMulti ? form[value]: {value: form[value], label: form[type]}}
                        isLoading={loading}
                        onChange={(value) => handleChange(value, type)}
                        onInputChange={handleInputChange}
                        options={options}
                        isMulti={isMulti}
                        className={"input-dropdown" + (errors[name] ? " error" : "")}
                    />
                :
                    <Select
                        isClearable
                        name={name}
                        value={isMulti ? form[value]: {value: form[value], label: form[type]}}
                        isLoading={loading}
                        onChange={(value) => handleChange(value, type)}
                        onInputChange={(value) => handleInputChange(value)}
                        options={options}
                        isMulti={isMulti}
                        className={"input-dropdown" + (errors[name] ? " error" : "")}
                        formatOptionLabel={formatOptionLabel}
                    />
            }

        </div>
    )
}

export default Dropdown