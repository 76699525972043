import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client';
import Table from '../../components/table';
import { INVENTORIES } from '../../queries/Inventory';
import AddInventory from '../../components/inventory/AddInventory.jsx';
import EditInventory from '../../components/inventory/EditInventory';
import Loading from '../../components/loading/loading';
import { DOWNLOAD_INVENTORY } from '../../mutations/inventory';
import { toast } from 'react-toastify';
import { DownloadIcon, UploadIcon } from '../../components/icons';
import fileDownload from 'js-file-download';
import moment from 'moment'
import UploadInventory from '../../components/inventory/UploadInventory';

function InventoriesTable(props) {
    const [searchTerm, setSearchTerm] = useState("")
    const [sortBy, setSortBy] = useState("id_DESC")
    const [modalIsOpen,setIsOpen] = React.useState(false);
    const [editInventoryModalIsOpen, setIsEditOpen] = React.useState(false);
    const [editId, setEditId] = React.useState("");
    const [inventoryListPage, setInventoryListPage] = useState(0);
    const [totalInventory, setTotalInventory] = useState(0);
    const [uploadInventoryModal, setUploadInventoryModal] = useState(false);
    const { loading, error, data, refetch } = useQuery(INVENTORIES, {
        variables: { search_term: searchTerm, order: sortBy, skip: inventoryListPage * 20 },
        fetchPolicy: "cache-and-network"
    });
    const [downloadInventory, { loading: loadingDownloadInventory, error: errorDownloadInventory, data: dataDownloadInventory }] = useMutation(DOWNLOAD_INVENTORY, {
        onCompleted: (downloadInv) => {
            var curr_date = moment().format("YYYY-MM-DD");
            fileDownload(downloadInv.inventoryDownload, `inventory-${curr_date}.csv`);
        },
        onError: (error) => {
            toast.error(error.message);
        },
    })

    const columns = React.useMemo(
        () => [
        
            {
                Header: 'Product Code',
                accessor: 'sku',
                sortBy: "sku"
            },
            {
                Header: 'Product Information',
                accessor: 'name',
                sortBy: "name"
            },
            {
                Header: 'Product Description',
                accessor: 'description',
                sortBy: "description"
            },
            {
                Header: 'Pictures',
                accessor: 'images[0].url',
                Cell: props => props.value ? <img src={props.value} textColor="#fff" text="Image" style={{width: "50px", height: "50px", maxWidth: "50px"}}/> : null,
            },
            {
                Header: 'Location',
                accessor: 'location',
                sortBy: "location"
            },
            {
                Header: 'Vendor',
                accessor: 'vendor.vendorName',
            },
            {
                Header: 'Cost Price',
                accessor: 'costPrice',
            },
            {
                Header: 'Retail Price',
                accessor: 'retailPrice',
            },
            {
                Header: 'Quantity in Stock',
                accessor: 'quantity',
                sortBy: "quantity"
            },
        ],
        []
    )
    var tableData;
    if (! data || ! data.inventories) {
        tableData = {inventories: []}
    } else if (data && data.inventories && totalInventory === 0) {
        tableData = data.inventories;
    }

    tableData = React.useMemo(() => tableData.inventories)

    const SelectRow = (id) => {
        setEditId(id);
        setIsEditOpen(true);
    }

    const sortColumn = (column) => {
        var order = ["_ASC", "_DESC"]
        if (column.sortBy) {
            var sort = column.sortBy;
            if (sort + "_ASC" !== sortBy && sort + "_DESC" !== sortBy) {
                setSortBy(sort + "_ASC");
            } else if (sort + "_ASC" == sortBy) {
                setSortBy(sort + "_DESC");
            } else {
                setSortBy("id_DESC");
            }
        }
    }

    const renderArrows = () => {
        var arrows, next_arrow, prev_arrow;
        
        if (data && data.inventories && data.inventories.inventoryCount > (20)) {
            arrows = true;

            if (inventoryListPage > 0) {
                prev_arrow = true;
            }

            if (data.inventories.inventoryCount > (inventoryListPage * 20 + 20)) {
                next_arrow = true;
            }
        }

        if (arrows) {
            return (
                <div className="table-pages">
                    <span>Showing {inventoryListPage * 20 + 1} - {inventoryListPage * 20 + 20 < data.inventories.inventoryCount ? inventoryListPage * 20 + 20 : data.inventories.inventoryCount} of {data.inventories.inventoryCount} results</span>
                    <i class={"fa fa-chevron-left arrow" + (prev_arrow ? " active" : "")} onClick={() => {
                        if (prev_arrow) {
                            setInventoryListPage(inventoryListPage - 1);
                        }
                    }}></i>
                    <i class={"fa fa-chevron-right arrow" + (next_arrow ? " active" : "")} onClick={() => {
                        if (next_arrow) {
                            setInventoryListPage(inventoryListPage + 1);
                        }
                    }}></i>
                </div>  
            )
        }
    }

    return (
        <div className="table-page ">
            <div className="page-header mobile-hidden">
                <div className="header-text" style={{display: 'flex'}}>
                    Inventory tracker
                    <div style={{cursor: "pointer", width: "25px", marginLeft: "10px", position: "relative"}} onClick={() => downloadInventory()}>
                        <DownloadIcon />
                    </div>
                    <div style={{cursor: "pointer", width: "25px", marginLeft: "10px"}} onClick={() => setUploadInventoryModal(true)}>
                        <UploadIcon />
                    </div>
                </div>
                <div>
                    <button onClick={() => setIsOpen(true)} className="button primary large">
                        <span className="button-symbol">+</span>Add inventory
                    </button>
                </div>
            </div>
            <div className="page-options">
                <div className="searchbar-container">
                    <div className="searchbar">
                        <div className="searchbar-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                width="20" height="20"
                                viewBox="0 0 24 24"
                                style={{fill: "#000000"}}><path d="M 13.261719 14.867188 L 15.742188 17.347656 C 15.363281 18.070313 15.324219 18.789063 15.722656 19.1875 L 20.25 23.714844 C 20.820313 24.285156 22.0625 23.972656 23.015625 23.015625 C 23.972656 22.058594 24.285156 20.820313 23.714844 20.25 L 19.191406 15.722656 C 18.789063 15.324219 18.070313 15.363281 17.347656 15.738281 L 14.867188 13.261719 Z M 8.5 0 C 3.804688 0 0 3.804688 0 8.5 C 0 13.195313 3.804688 17 8.5 17 C 13.195313 17 17 13.195313 17 8.5 C 17 3.804688 13.195313 0 8.5 0 Z M 8.5 15 C 4.910156 15 2 12.089844 2 8.5 C 2 4.910156 4.910156 2 8.5 2 C 12.089844 2 15 4.910156 15 8.5 C 15 12.089844 12.089844 15 8.5 15 Z"></path></svg>
                        </div>
                        <input onChange={(e) => {
                            setSearchTerm(e.target.value); 
                            setInventoryListPage(0);
                            refetch({search_term: e.target.value, order: sortBy, skip: inventoryListPage * 20})
                        }} placeholder="Search..." value={searchTerm}/>
                    </div>
                    <div className="searchbar-cancel" onClick={() => {
                        setSearchTerm(""); 
                        setInventoryListPage(0);
                        refetch({search_term: "", order: sortBy, skip: inventoryListPage * 20});
                    }}>
                        Cancel
                    </div>
                </div>
                {renderArrows()}
            </div>
                {
                    error ?
                      <h1>Error</h1>
                    :
                        <div className="table">
                            <Table 
                                columns={columns} 
                                data={tableData} 
                                sort={sortBy} 
                                onHeaderPress={sortColumn}
                                onRowPress={(row) => SelectRow(row.original.id)}
                            />
                            {
                                loading && (
                                    <div className="loading-mask">
                                        <Loading />
                                    </div>
                                )
                            }
                        </div>
                      
                }
                {
                    modalIsOpen && (
                        <AddInventory isOpen={modalIsOpen} setIsOpen={setIsOpen}/>
                    )
                }
                {
                    editInventoryModalIsOpen && (
                        <EditInventory isOpen={editInventoryModalIsOpen} setIsOpen={setIsEditOpen} inventoryId={editId}/>
                    )
                }
                {
                    uploadInventoryModal && (
                        <UploadInventory isOpen={uploadInventoryModal} setIsOpen={setUploadInventoryModal} />
                    )
                }
                
        </div>
        
    )
}

export default InventoriesTable
