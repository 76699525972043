
import React, { useState, useRef } from 'react'
import Modal from 'react-modal';
import { useMutation } from '@apollo/client';
import { DELETE_ORDER } from '../../mutations/order';
import { ORDERS } from '../../queries/Orders';
import { toast } from 'react-toastify';
 
function DeleteOrder(props) {
    const { isOpen, setIsOpen, id, setDeleteOrderModal } = props;

    const default_form = {
        id: ""
    }

    Modal.setAppElement('#root');

    const [deleteOrder, { loading: loadingOrder, error: errorOrder, data: dataOrder }] = useMutation(DELETE_ORDER, {
        onCompleted:() => {
            setIsOpen(false);
            toast.success("Successfully Removed Order!");
        }
    });

    return (
        <Modal 
            isOpen={isOpen}
            contentLabel="Delete Order"
            onRequestClose={() => {setDeleteOrderModal(false)}}
            className="delete-modal-overlay"
        >
            <div className="delete-modal">
                <div className="confirm-delete">Are you sure you want to delete this order?</div>
                <div className="desc-delete">This order will not be recoverable.</div>
                <div 
                    className="button primary error" 
                    onClick={() => {
                            deleteOrder({
                                variables: {
                                    orderId: id
                                },
                                refetchQueries: [{ query: ORDERS, variables: { search_term: "", order: "id_DESC" }}]
                            });
                    }
                }>Delete Order</div>
                <div className="cancel-delete" onClick={() => setDeleteOrderModal(false)}>Cancel</div>
            </div>
        </Modal>
    )
}

export default DeleteOrder