import React, { useState } from 'react';
import './header.scss';
import { Link, useLocation } from 'react-router-dom';
import AddOrder from '../orders/AddOrder';
import AddInventory from '../inventory/AddInventory';

function Header({ loggedIn, user, client, loading, mobileMenu, setMobileMenu, setAddOrderIsOpen, addOrderModelIsOpen }) {
    const location = useLocation();

    const [addInventoryModalIsOpen,setAddInventoryIsOpen] = React.useState(false);

    const renderHeaderName = () => {
        if (location.pathname === "/") {
            return "Order Calendar"
        } else if (location.pathname === "/orders") {
            return "Order Tracker"
        } else if (location.pathname === "/inventory") {
            return "Inventory Tracker"
        }
    }

    const renderMobileAction = () => {
        var action = null

        if (location.pathname === "/orders") {
            action = (<div className="action-circle" onClick={() => setAddOrderIsOpen(true)}>
                        <span className="button-symbol">+</span>
                    </div>)

        } else if (location.pathname === "/inventory") {
            action = (<div className="action-circle" onClick={() => setAddInventoryIsOpen(true)}>
                        <span className="button-symbol">+</span>
                    </div>)
        }

        if (action) {
            return (
                <div className="mobile-action">
                    {action}
                    <div className="action-text">Add New</div>
                </div>
            )
        }
    }

    if (loggedIn) {
        return (
            <div className="header">
                <div className="mobile-hamburger" onClick={() => setMobileMenu(!mobileMenu)}>
                    <div className={"bar1" + (mobileMenu ? " change" : "")}></div>
                    <div className={"bar2" + (mobileMenu ? " change" : "")}></div>
                    <div className={"bar3" + (mobileMenu ? " change" : "")}></div>
                </div>
                <div className="mobile-header">
                    {renderHeaderName()}
                </div>
                <div className="desktop-logo">
                    <img src={require("../../images/Visort.png")} className="header-logo" alt="VisortLogo" title="VisortLogo" />
                </div>
                {renderMobileAction()}
                <div className="header-options">
                    <div className={"button primary"} onClick={() => {setAddOrderIsOpen(true)}}>New Order Submission</div>
                    <Link to="/" className={"button primary" + (location.pathname === "/orders" ? " selected" : "")} >Order Tracker</Link>
                    <Link to="/calendar" className={"button primary" + (location.pathname === "/" ? " selected" : "")}>Calendar</Link>
                    <Link to="/inventory" className={"button primary" + (location.pathname === "/inventory" ? " selected" : "")}>Inventory Tracker</Link>
                    
                    <a href="" className={"button primary" } onClick={() => {
                        localStorage.removeItem('authorization');
                        client.resetStore();
                    }}>
                        Logout
                    </a>
                </div>
                {
                    addOrderModelIsOpen && (
                        <AddOrder isOpen={addOrderModelIsOpen} setIsOpen={setAddOrderIsOpen} order={"id_DESC"} orderTypes={"Active"}/>
                    )
                }
                {
                    addInventoryModalIsOpen && (
                        <AddInventory isOpen={addInventoryModalIsOpen} setIsOpen={setAddInventoryIsOpen}/>
                    )
                }
            </div>
        )
    } else {
        return (
            <div className="header">
            </div>
        )
    }
}

export default Header;
