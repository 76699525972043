
import React, { useState } from 'react'
import Modal from 'react-modal';
import Input from '../input';
import Dropdown from '../dropdown';
import { useQuery, useMutation } from '@apollo/client';
import Dropzone from 'react-dropzone'
import axios from 'axios';
import { INVENTORIES } from '../../queries/Inventory';
import { toast } from 'react-toastify';
import { CUSTOMERS, CUSTOMER } from '../../queries/customers';
import moment from 'moment'
import { ADD_ORDER } from '../../mutations/order';
import { ORDERS, ORDER_TYPES } from '../../queries/Orders';
import Select from 'react-select';
import DatePicker from "react-datepicker";

import "./styles.scss"

import "react-datepicker/dist/react-datepicker.css";
import OrderCalendar from '../OrderCalendar';
import priorities from '../../utils/priorities';
import Loading from '../loading/loading';
 

function AddOrder(props) {
    const { isOpen, setIsOpen } = props;

    const default_form = {
        itemType: [],
        quantity: 0,
        priority: {value: 2, label: 'Medium'},
        description: "",
        orderType: [],
        comments: "",
        amountOwed: "",
        estDays: 7,
        selectedInventory: {},
        startDate: moment().format("MM-DD-YYYY"),
        endDate: moment().add(7, 'days').format("MM-DD-YYYY"),
        primaryOrderType: {recommendedNumDays: 0}
    }

    const default_customer = {
        customerId: "",
        customerName: "",
        companyName: "",
        email: "",
        phone: "",
        address1: "",
        address2: "",
        city: "",
        province: "",
        postal: "",
    }

    const [orderForm, setOrderForm] = useState({
        ...default_customer,
       ...default_form 
    });

    const [errors, setErrors] = useState({});
    const [errorMessages, setErrorMessages] = useState([]);
    const [customersList, setCustomersList] = useState([]);
    const [overInventory, setOverInventory] = useState({});
    const [search, setSearch] = useState({inventory: "", selectedInventory: ""});
    const [companyList, setCompanyList] = useState([])
    const [orderTypeList, setOrderTypeList] = useState([])
    const [loadingCustomersList, setLoadingCustomersList] = useState(true)
    const [loadingOrderTypeList, setLoadingOrderTypeList] = useState(true)
    const [inventoryListPage, setInventoryListPage] = useState(0);

    const { loading: loadingCustomers, error: errorCustomers, data: dataCustomers, refetch: refetchCustomers } = useQuery(CUSTOMERS, {
        variables: { customerName: "", companyName: "", order: "id_DESC" },
    });

    const { loading: loadingCustomer, error: errorCustomer, data: dataCustomer, refetch: refetchCustomer } = useQuery(CUSTOMER, {
        variables: { customerId: orderForm.customerId },
    });

    const { loading: loadingOrders, error: errorOrders, data: dataOrders, refetch: refetchOrders } = useQuery(ORDERS, {
        variables: { order: "priority_DESC", search_term: "", startDate: orderForm.startDate, endDate: orderForm.startDate },
    });

    const { loading: loadingInventory, error: errorInventory, data: dataInventory, refetch: refetchInventory } = useQuery(INVENTORIES, {
        variables: { order: "name_DESC", search_term: search.inventory, skip: inventoryListPage * 20 },
    });

    const { loading: loadingOrderTypes, error: errorOrderTypes, data: dataOrderTypes, refetch: refetchOrderTypes } = useQuery(ORDER_TYPES);

    const [addOrder, { loading: loadingAddOrder, error: errorAddOrder, data: dataAddOrder }] = useMutation(ADD_ORDER, {
        onCompleted: () => {
            setIsOpen(false);
            toast.success("Successfully Added New Order!");
        },
        onError: (error) => {
            toast.error(error.message);
        },
        refetchQueries: () => [{ query: ORDERS, variables: { search_term: "", order: props.order, startDate: props.startDate, endDate: props.endDate, types: props.queryTypes }}, { query: INVENTORIES, variables: { search_term: "", order: "id_DESC" }}]
    })

    const sortObjects = (a, b) => {
        if (a.label < b.label) {
            return -1;
        } else if (a.label > b.label) {
            return 1;
        } else {
            return 0;
        }
    }

    if (!loadingCustomers && loadingCustomersList && dataCustomers.customers ) {
        setLoadingCustomersList(false);
        var customers = []
        var companies = []

        dataCustomers.customers.map((customer) => {
            if (customer.customerName) {
                customers.push({value: customer.id, label: customer.customerName, type: "customer"})
            }
            
            if (customer.companyName) {
                companies.push({ value: customer.id, label: customer.companyName, type: "company" });
            }
        })

        companies.sort(sortObjects);
        customers.sort(sortObjects);

        setCompanyList(companies);
        setCustomersList(customers);
    }
    
    if (!loadingCustomer && dataCustomer && dataCustomer.customer && dataCustomer.customer.id === orderForm.customerId && (orderForm.customerName !== dataCustomer.customer.customerName && orderForm.companyName !== dataCustomer.customer.companyName)) {
        setOrderForm({
            ...orderForm,
            ...dataCustomer.customer,
        })
    }

    var inventoryList = []
    if(! loadingInventory && dataInventory.inventories) {
        inventoryList = dataInventory.inventories.inventories
    }

    var ordersList = []
    if (! loadingOrders && dataOrders.orders) {
        ordersList = dataOrders.orders
    }

    var orderTypesList = []
    if (! loadingOrderTypes && loadingOrderTypeList && dataOrderTypes.orderTypes) {
        setLoadingOrderTypeList(false)
        dataOrderTypes.orderTypes.map((orderType) => {
            orderTypesList.push({value: orderType, label: `${orderType.name}`, type: "orderType"})
        });

        orderTypesList.sort(sortObjects);

        setOrderTypeList(orderTypesList);
    }

    Modal.setAppElement('#root');

    const handleInputChange = (e) => {
        var name = e.target.name;
        var value = e.target.value;

        setOrderForm({
            ...orderForm,
            [name]: value
        })

        setErrors({
            ...errors,
            [name]: false
        })
    }

    const handleDropdownInputChange = (value, type) => {
        if (value) {
            setOrderForm({
                ...orderForm,
                [type]: value
            })

            setErrors({
                ...errors,
                [type]: false
            })
        }
    }

    const handleDropdownChange = ( value, type ) => {
        if (value) {
            if (value.__isNew__) {
                setOrderForm({
                    ...orderForm,
                    [type]: value.value
                })
            } else {
                setOrderForm({
                    ...orderForm,
                    customerId: value.value
                })
            }

            setErrors({
                ...errors,
                customerName: false,
                companyName: false
            })
        } else {
            setOrderForm({
                ...orderForm,
                ...default_customer
            })
        }

    }

    const handlePriorityDropdownChange = (value) => {
        if (value) {
            setOrderForm({
                ...orderForm,
                priority: value
            })

            setErrors({
                ...errors,
                priority: false
            })
        }


    }

    const handleDropdownMultiChange = ( value, type ) => {
        //Check the max num days and set end date
        var primaryOrderType = orderForm.primaryOrderType
        var endDate = orderForm.endDate;
        var estDays = orderForm.estDays;

        if (value) {
            for (var i = 0; i < value.length; i++) {
                if (parseInt(value[i].value.recommendedNumDays) > estDays || primaryOrderType.recommendedNumDays === 0) {
                    primaryOrderType = value[i].value;
                    estDays = parseInt(value[i].value.recommendedNumDays);
                    endDate = moment(orderForm.startDate, "MM-DD-YYYY").clone().add(estDays, 'days').format("MM-DD-YYYY")
                }
            }
        } else {
            estDays = 7;
            endDate = moment(orderForm.startDate, "MM-DD-YYYY").clone().add(estDays, 'days').format("MM-DD-YYYY")
        }

        setOrderForm({
            ...orderForm,
            orderType: value,
            primaryOrderType,
            estDays,
            endDate
        })
    }

    const addInventory = (inventory) => {
        setOrderForm({
            ...orderForm,
            selectedInventory: {
                ...orderForm.selectedInventory,
                [inventory.id]: {
                    ...inventory,
                    previousQuantity: inventory.quantity,
                    quantity: 1
                }
            }
        })

        if (inventory.quantity < 1) {
            setOverInventory({
                ...overInventory,
                [inventory.id]: true
            })
        }
    }

    const submitOrder = () => {
        var required_fields = ['email', 'phone', 'quantity', 'startDate', 'estDays'];
        var error = false;
        var errs = {};
        var errMessages = [];

        required_fields.map((field) => {
            if (orderForm[field] === "") {
                if (! error) {
                    errMessages.push("Please ensure all mandatory fields are filled")
                }

                error = true;
                errs[field] = true;
            }

            if (field === "quantity" || field === "estDays") {
                if (parseInt(field) <= 0) {
                    errs[field] = true;
                    error = true;
                    errMessages.push("Please ensure all number fields are only valid numbers")
                }
            }
        })

        if (orderForm.orderType.length === 0) {
            errs['orderType'] = true;
            errMessages.push('Please ensure at least one Item Type is selected.')
            error = true;
        }

        if (error) {
            setErrors(errs)
            setErrorMessages(errMessages)
        } else {
            var inventory = [];
            var orderTypes = [];

            orderForm.orderType.map((type) => {
                orderTypes.push(type.value.id)
            })

            Object.keys(orderForm.selectedInventory).map((invKey) => {
                var inv = orderForm.selectedInventory[invKey];

                inventory.push({
                    quantity: parseInt(inv.quantity),
                    inventoryId: inv.id,
                    beforeOrderQuantity: inv.previousQuantity
                })
            })

            var customerForm = {}

            if(orderForm.customerId !== "") {
                customerForm = {
                    customerId: orderForm.customerId
                }
            } else {
                customerForm = {
                    customerName: orderForm.customerName,
                    companyName: orderForm.companyName,
                    customerEmail: orderForm.email,
                    customerPhone: orderForm.phone,
                    customerAddress1: orderForm.address1,
                    customerAddress2: orderForm.address2,
                    customerCity: orderForm.city,
                    customerProvince: orderForm.province,
                    customerPostal: orderForm.postal,
                }
            }

            addOrder({
                variables: {
                    ...customerForm,
                    orderTypes,
                    primaryOrderType: orderForm.primaryOrderType.id,
                    inventory,
                    comments: orderForm.comments,
                    description: orderForm.description,
                    amountOwed: orderForm.amountOwed,
                    priority: orderForm.priority.value,
                    quantity: parseInt(orderForm.quantity),
                    startDate: orderForm.startDate,
                    endDate: orderForm.endDate,
                    webOrderNumber: orderForm.webOrderNumber
                },
            })
        }
    }

    const updateInventory = (e, inventory) => {
        setOrderForm({
            ...orderForm,
            selectedInventory: {
                ...orderForm.selectedInventory,
                [inventory.id]: {
                    ...inventory,
                    quantity: e.target.value
                }
            }
        })

        if (parseInt(e.target.value) > inventory.previousQuantity) {
            setOverInventory({
                ...overInventory,
                [inventory.id]: true
            })
        } else {
            setOverInventory({
                ...overInventory,
                [inventory.id]: false
            })
        }
    }

    const handleNumDaysInputChange = (e) => {
        var num = parseInt(e.target.value);
        var endDate = moment(orderForm.startDate, "MM-DD-YYYY").clone().add(num, 'days').format("MM-DD-YYYY")

        setOrderForm({
            ...orderForm,
            estDays: e.target.value,
            endDate
        })

        if (e.target.value < orderForm.primaryOrderType.recommendedNumDays) {
            setErrors({
                estDays: true,
            })
        }
    }

    const removeInventory = (inventory) => {
        var selectedInventory = orderForm.selectedInventory;
        delete selectedInventory[inventory.id];

        setOrderForm({
            ...orderForm,
            selectedInventory
        })
    }

    const updateDate = (day) => {
        var estDays = parseInt(orderForm.estDays)
        console.log(day);
        setOrderForm({ 
            ...orderForm, 
            endDate: moment(day).clone().add(estDays, 'days').format("MM-DD-YYYY"),
            startDate: moment(day).format("MM-DD-YYYY") 
        });

        refetchOrders({ order: "priority_DESC", search_term: "", startDate: moment(day).format("MM-DD-YYYY"), endDate: moment(day).format("MM-DD-YYYY")})
    }

    const scrolledOnInventory = (e) => {
        console.log(e.scrollHeight);
    }

    return (
        <Modal 
            isOpen={isOpen}
            contentLabel="Add New Order Form"
            onRequestClose={() => {setIsOpen(false)}}
        >
            <div className="page-header">
                <div className="header-text">
                    Add order
                </div>
                <div className="page-header-close">
                    <div onClick={() => {setIsOpen(false)}}>
                        &#10005;
                    </div>
                </div>
            </div>
            <div className="page">
                <div className="form-row">
                    <Dropdown
                        form={orderForm}
                        name={"companyName"}
                        handleInputChange={handleDropdownInputChange}
                        handleChange={handleDropdownChange}
                        title={"Company Name"}
                        errors={errors}
                        options={companyList}
                        loading={loadingCustomersList}
                        type="companyName"
                        value={"customerId"}
                        isCreatable={true}
                    />
                    <Dropdown
                        form={orderForm}
                        name={"customerName"}
                        handleInputChange={handleDropdownInputChange}
                        handleChange={handleDropdownChange}
                        title={"Customer Name"}
                        errors={errors}
                        options={customersList}
                        loading={loadingCustomersList}
                        type="customerName"
                        value={"customerId"}
                        isCreatable={true}
                    />
                </div>
                <div className="form-row">
                    <Input
                        form={orderForm}
                        name={"email"}
                        handleInputChange={handleInputChange}
                        title={"Customer Email Address"}
                        errors={errors}
                        disabled={orderForm.customerId ? true : false}
                    />

                    <Input
                        form={orderForm}
                        name={"phone"}
                        handleInputChange={handleInputChange}
                        title={"Customer Phone Number"}
                        errors={errors}
                        disabled={orderForm.customerId ? true : false}
                    />
                </div>
                <div className="form-row">
                    <Input
                        form={orderForm}
                        name={"address1"}
                        handleInputChange={handleInputChange}
                        title={"Address Line 1"}
                        errors={errors}
                        disabled={orderForm.customerId ? true : false}
                    />

                    <Input
                        form={orderForm}
                        name={"address2"}
                        handleInputChange={handleInputChange}
                        title={"Address Line 2"}
                        errors={errors}
                        disabled={orderForm.customerId ? true : false}
                    />
                </div>
                <div className="form-row">
                    <Input
                        form={orderForm}
                        name={"city"}
                        handleInputChange={handleInputChange}
                        title={"City"}
                        errors={errors}
                        disabled={orderForm.customerId ? true : false}
                    />

                    <Input
                        form={orderForm}
                        name={"province"}
                        handleInputChange={handleInputChange}
                        title={"Province"}
                        errors={errors}
                        disabled={orderForm.customerId ? true : false}
                    />
                </div>
                <div className="form-row">
                    <Input
                        form={orderForm}
                        name={"postal"}
                        handleInputChange={handleInputChange}
                        title={"Postal Code"}
                        errors={errors}
                        disabled={orderForm.customerId ? true : false}
                    />

                    <Dropdown
                        form={orderForm}
                        name={"orderType"}
                        handleInputChange={() => {}}
                        handleChange={handleDropdownMultiChange}
                        title={"Item Type"}
                        errors={errors}
                        options={orderTypeList}
                        loading={loadingOrderTypes}
                        type="orderType"
                        value={"orderType"}
                        isMulti={true}
                        formatOptionLabel={({ value, label }) => (
                            <div style={{display: "flex"}}>
                                <div style={{marginRight: "5px"}}>{label}</div>
                                <div style={{color: "dimgray", marginLeft: "auto"}}>{value.recommendedNumDays} Days</div>
                            </div>
                        )}
                    />
                </div>
                <div className="form-row">
                    <Input
                        form={orderForm}
                        name={"quantity"}
                        handleInputChange={handleInputChange}
                        title={"Quantity"}
                        errors={errors}
                    />

                    <div className={"input-container"}>
                        <div className={"input-title"}>
                            Priority
                        </div>
                        <Select
                            isClearable={false}
                            name={'priority'}
                            value={orderForm.priority}
                            isLoading={false}
                            onChange={(value) => handlePriorityDropdownChange(value, "priority")}
                            options={[{value: 1, label: "Low"}, {value: 2, label: "Medium"}, {value: 3, label: "High"}]}
                            isMulti={false}
                            className={"input-dropdown" + (errors.priority ? " error" : "")}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <Input
                        form={orderForm}
                        name={"amountOwed"}
                        handleInputChange={handleInputChange}
                        title={"Amount Owed"}
                        errors={errors}
                    />
                    <Input
                        form={orderForm}
                        name={"webOrderNumber"}
                        handleInputChange={handleInputChange}
                        title={"Web Order #"}
                        errors={errors}
                    />
                </div>
                <div className="form-row">
                    <div className={"input-container"}>
                        <div className={"input-title"}>
                            Description
                        </div>
                        <textarea 
                            name={'description'}
                            onChange={handleInputChange}
                            className={"input-box" + (errors['description'] ? " error" : "")}
                            rows="5"
                        >{orderForm['description']}</textarea>
                    </div>
                    <div className={"input-container"}>
                        <div className={"input-title"}>
                            Comments
                        </div>
                        <textarea 
                            name={'comments'}
                            onChange={handleInputChange}
                            className={"input-box" + (errors['comments'] ? " error" : "")}
                            rows="5"
                        >{orderForm['comments']}</textarea>
                    </div>
                </div>
                <div className="form-row ">
                    <div className={"input-container"}>
                        <div className={"input-title"}>
                            Inventory Search {(dataInventory && dataInventory.inventories) && (<span>({dataInventory.inventories.inventoryCount} Results)</span>)}
                        </div>
                        <div className="inventory-box">
                            <div className="searchbar-container">
                                <div className="searchbar">
                                    <div className="searchbar-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                            width="20" height="20"
                                            viewBox="0 0 24 24"
                                            style={{fill: "#000000"}}><path d="M 13.261719 14.867188 L 15.742188 17.347656 C 15.363281 18.070313 15.324219 18.789063 15.722656 19.1875 L 20.25 23.714844 C 20.820313 24.285156 22.0625 23.972656 23.015625 23.015625 C 23.972656 22.058594 24.285156 20.820313 23.714844 20.25 L 19.191406 15.722656 C 18.789063 15.324219 18.070313 15.363281 17.347656 15.738281 L 14.867188 13.261719 Z M 8.5 0 C 3.804688 0 0 3.804688 0 8.5 C 0 13.195313 3.804688 17 8.5 17 C 13.195313 17 17 13.195313 17 8.5 C 17 3.804688 13.195313 0 8.5 0 Z M 8.5 15 C 4.910156 15 2 12.089844 2 8.5 C 2 4.910156 4.910156 2 8.5 2 C 12.089844 2 15 4.910156 15 8.5 C 15 12.089844 12.089844 15 8.5 15 Z"></path></svg>
                                    </div>
                                    <input 
                                        placeholder="Search..." 
                                        value={search.inventory} 
                                        onChange={(e) => setSearch({...search, inventory: e.target.value})}
                                    />
                                </div>
                                <div className="searchbar-cancel" onClick={() => setSearch({...search, inventory: ""})}>
                                    Cancel
                                </div>
                            </div>
                            <div className="inventory-list">
                                {
                                    loadingInventory ?
                                        <Loading />
                                    :
                                    inventoryList.length > 0 && (inventoryList.reduce((filtered, inventory) => {
                                        var selected = false;
                                        if (inventory.id in orderForm.selectedInventory) {
                                            selected = true;
                                        }

                                        filtered.push(
                                            <div className="inventory-row">
                                                <div style={{width: "10%"}}><input type="checkbox" checked={selected} onChange={() => selected ? removeInventory(inventory) : addInventory(inventory)}/></div>
                                                <div style={{width: "35%"}} className="inventory-row-text">{inventory.name}</div>
                                                <div style={{width: "30%"}} className="inventory-row-text">SKU # {inventory.sku}</div>
                                                <div style={{width: "25%"}} className="inventory-row-subtext">QTY in stock: {inventory.quantity}</div>
                                            </div>
                                        )

                                        return filtered;
                                    }, [])
                            )}
                            </div>
                        </div>
                    </div>
                    <div className={"input-container"}>
                        <div className={"input-title"}>
                            Selected Inventory
                        </div>
                        <div className="inventory-box">
                            <div className="searchbar-container">
                                <div className="searchbar">
                                    <div className="searchbar-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                            width="20" height="20"
                                            viewBox="0 0 24 24"
                                            style={{fill: "#000000"}}><path d="M 13.261719 14.867188 L 15.742188 17.347656 C 15.363281 18.070313 15.324219 18.789063 15.722656 19.1875 L 20.25 23.714844 C 20.820313 24.285156 22.0625 23.972656 23.015625 23.015625 C 23.972656 22.058594 24.285156 20.820313 23.714844 20.25 L 19.191406 15.722656 C 18.789063 15.324219 18.070313 15.363281 17.347656 15.738281 L 14.867188 13.261719 Z M 8.5 0 C 3.804688 0 0 3.804688 0 8.5 C 0 13.195313 3.804688 17 8.5 17 C 13.195313 17 17 13.195313 17 8.5 C 17 3.804688 13.195313 0 8.5 0 Z M 8.5 15 C 4.910156 15 2 12.089844 2 8.5 C 2 4.910156 4.910156 2 8.5 2 C 12.089844 2 15 4.910156 15 8.5 C 15 12.089844 12.089844 15 8.5 15 Z"></path></svg>
                                    </div>
                                    <input 
                                        placeholder="Search..." 
                                        value={search.selectedInventory} 
                                        onChange={(e) => setSearch({...search, selectedInventory: e.target.value})}
                                    />
                                </div>
                                <div className="searchbar-cancel" onClick={() => setSearch({...search, selectedInventory: ""})}>
                                    Cancel
                                </div>
                            </div>
                            <div className="inventory-list">
                                {Object.keys(orderForm.selectedInventory).length > 0 && (Object.keys(orderForm.selectedInventory).reduce((filtered, inventoryKey) => {
                                        var inventory = orderForm.selectedInventory[inventoryKey]

                                        if ((inventory.name && inventory.name.includes(search.selectedInventory)) || inventory.sku.includes(search.selectedInventory)) {
                                            filtered.push(
                                                <div className="inventory-row" style={overInventory[inventoryKey] ? {color: "red"} : {color: "black"}}>
                                                    <div style={{width: "10%"}}><input style={{width: "70%"}} type="number" min={"0"} value={inventory.quantity} onChange={(e) => updateInventory(e, inventory)}/> </div>
                                                    <div style={{width: "10%"}}> / {inventory.previousQuantity}</div>
                                                    <div style={{width: "30%"}} className="inventory-row-text">{inventory.name}</div>
                                                    <div style={{width: "30%"}} className="inventory-row-text">SKU # {inventory.sku}</div>
                                                    <div style={{width: "15%"}} className="inventory-row-text">${inventory.costPrice}</div>
                                                    <div style={{width: "5%"}} style={{cursor: 'pointer'}} onClick={() => removeInventory(inventory)}>&#10005;</div>
                                                </div>
                                            )
                                        }

                                        return filtered;
                                    }, [])
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-50">
                        <div className="form-row">
                            <div className={"input-container"}>
                                {
                                    <div className={"input-title"}>
                                        Target Start Date
                                    </div>
                                }
                                <DatePicker
                                    selected={Date.parse(orderForm.startDate)}
                                    onChange={(date) => {updateDate(date)}}
                                    minDate={new Date()}
                                />
                            </div>
                            <Input
                                form={orderForm}
                                handleInputChange={handleNumDaysInputChange}
                                title={"Est. Days to Completion"}
                                name={"estDays"}
                                errors={errors}
                                type={"number"}
                            />
                        </div>

                        <OrderCalendar startDate={orderForm.startDate} endDate={orderForm.endDate} updateDate={(date) => updateDate(date)} />
                    </div>
                    <div className="form-50 order-day">
                        <div className="calendar-header">
                            <span className="calendar-arrow" onClick={() => {
                                var day = moment(orderForm.startDate, "MM-DD-YYYY").add(-1, 'day').format("MM-DD-YYYY");
                                updateDate(day)
                            }}> <i class="fa fa-chevron-left"></i> </span>
                            <span className="calendar-dates"> {moment(orderForm.startDate, "MM-DD-YYYY").format("MMM DD")} -  {moment(orderForm.startDate, "MM-DD-YYYY").format("dddd")}</span>
                            <span className="calendar-arrow" onClick={() => {
                                var day = moment(orderForm.startDate, "MM-DD-YYYY").add(1, 'day').format("MM-DD-YYYY");
                                updateDate(day)
                            }}> <i class="fa fa-chevron-right"></i> </span>
                        </div>

                        <div className="order-list">
                            {
                                ordersList.map((order) => {
                                    var colour = priorities[order.priority].color
                                    var client = order.customer.companyName + " - " + order.customer.customerName;

                                    return (
                                        <div className={"single-order"} style={{borderLeftColor: colour}}>
                                            <div className="order-details">
                                                <div>ORDER # {order.orderNumber}</div>
                                                <div>{client}</div>
                                            </div>
                                            <div className="order-dates">
                                                <div>STARTED: {moment(order.startDate).format("MMM DD, YYYY")}</div>
                                                <div>TO BE COMPLETED: {moment(order.endDate).format("MMM DD, YYYY")}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <button className="button primary large" onClick={() => {submitOrder()}}>Submit</button>
                </div>
            </div>
        </Modal>
    )
}

export default AddOrder