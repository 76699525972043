import { gql } from '@apollo/client';

export const ADD_INVENTORY = gql`
    mutation addInventory($images: [String]!, $vendorId: String, $vendorName: String, $vendorPhone: String, $vendorEmail: String, $vendorAddress1: String, $vendorAddress2: String, $vendorCity: String, $vendorProvince: String, $vendorPostal: String, $sku: String!, $name: String!, $description: String, $quantity: Int!, $location: String, $retailPrice: Float, $costPrice: Float) {
        addInventory(images: $images, vendorId: $vendorId, vendorName: $vendorName, vendorPhone: $vendorPhone, vendorEmail: $vendorEmail, vendorAddress1: $vendorAddress1, vendorAddress2: $vendorAddress2, vendorCity: $vendorCity, vendorProvince: $vendorProvince, vendorPostal: $vendorPostal, sku: $sku, name: $name, description: $description, quantity: $quantity, location: $location, retailPrice: $retailPrice, costPrice: $costPrice) {
            id
            name
            sku
            images{
                url
            }
            quantity
            description
            retailPrice
            costPrice
            vendor{
                vendorName
                phone
                email
            }
        }
    }
`;

export const UPDATE_INVENTORY = gql`
    mutation updateInventory($inventoryId: String!, $quantity: Int!, $previousQuantity: Int!, $location: String, $name: String!, $description: String!, $retailPrice: Float, $costPrice: Float) {
        updateInventory(inventoryId: $inventoryId, quantity: $quantity, previousQuantity: $previousQuantity, location: $location, name: $name, description: $description, retailPrice: $retailPrice, costPrice: $costPrice) {
            id
            name
            sku
            images{
                url
            }
            quantity
            description
            vendor{
                vendorName
                phone
                email
            }
        }
    }
`;

export const DELETE_INVENTORY = gql`
    mutation deleteInventory($inventoryId: String!) {
        deleteInventory(inventoryId: $inventoryId) {
            message
        }
    }
`;

export const DOWNLOAD_INVENTORY = gql`
    mutation inventoryDownload {
        inventoryDownload
    }
`;

export const UPLOAD_INVENTORY = gql`
    mutation inventoryUpload($csvFile: String!) {
        inventoryUpload(csvFile: $csvFile) {
            message
        }
    }
`;