
import React, { useState, useRef } from 'react'
import Modal from 'react-modal';
import Input from '../input';
import Dropdown from '../dropdown';
import { useQuery, useMutation } from '@apollo/client';
import Dropzone from 'react-dropzone'
import axios from 'axios';
import { UPDATE_INVENTORY } from '../../mutations/inventory';
import { INVENTORY, INVENTORIES } from '../../queries/Inventory';
import { toast } from 'react-toastify';
import Barcode from 'react-barcode';
import ReactToPrint from "react-to-print";
import DeleteInventory from './DeleteInventory';


class SkuBarcode extends React.Component {
    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignSelf: 'left', alignItems: 'center', margin: 0, padding: 0, width: '2.125in', height: '1in'}}>
                <Barcode value={this.props.sku} className="sku-barcode"/>
                <div>Retail Price: ${this.props.retailPrice}</div>
            </div>
            
        )
    }
  }

function EditInventory(props) {
    const { isOpen, setIsOpen } = props;
    const componentRef = useRef();

    const default_form = {
        inventoryId: "",
        previousQuantity: 0,
        quantity: 0,
        previousLocation: "",
        location: "",
        name: "",
        description: "",
        costPrice: 0.0,
        retailPrice: 0.0
    }

    const [inventoryForm, setInventoryForm] = useState({
       ...default_form 
    });

    const [errors, setErrors] = useState({});
    const [errorMessages, setErrorMessages] = useState([]);
    const [deleteInventoryModalIsOpen, setDeleteInventoryModal] = React.useState(false);
    
    const [updateInventory, { loading: loadingAddVentory, error: errorAddInventory, data: dataAddInventory }] = useMutation(UPDATE_INVENTORY, {
        onCompleted: () => {
            setIsOpen(false);
            toast.success("Successfully Updated Inventory");
        },
        onError: (error) => {
            toast.error(error.message);
        },
    })

    const { loading: loadingInventory, error: errorInventory, data: dataInventory } = useQuery(INVENTORY, {
        variables: { inventoryId: props.inventoryId },
    });

    if (!loadingInventory && dataInventory && dataInventory.inventory.id !== inventoryForm.inventoryId) {
        setInventoryForm({
            inventoryId: dataInventory.inventory.id,
            quantity: dataInventory.inventory.quantity,
            previousQuantity: dataInventory.inventory.quantity,
            previousLocation: dataInventory.inventory.location,
            location: dataInventory.inventory.location,
            name: dataInventory.inventory.name,
            description: dataInventory.inventory.description,
            costPrice: dataInventory.inventory.costPrice ? dataInventory.inventory.costPrice : 0.0,
            retailPrice: dataInventory.inventory.retailPrice ? dataInventory.inventory.retailPrice : 0.0
        })
    }

    Modal.setAppElement('#root');

    const handleInputChange = (e) => {
        var name = e.target.name;
        var value = e.target.value;

        setInventoryForm({
            ...inventoryForm,
            [name]: value
        })

        setErrors({
            ...errors,
            [name]: false
        })
    }

    const submitInventory = () => {
        var required_fields = ['quantity', 'name'];
        var error = false;
        var messages = []

        required_fields.map((field) => {
            if (inventoryForm[field] === "") {
                var err_copy = errors;
                err_copy[field] = true;

                setErrors(err_copy)

                if (! error) {
                    messages.push("Please ensure all mandatory fields are filled.")
                }

                error = true;
            }

            if (field === 'quantity' && ! Number.isInteger(parseInt(inventoryForm[field]))) {
                var err_copy = errors;
                err_copy[field] = true;

                setErrors(err_copy)

                if (! error) {
                    messages.push("Please ensure quantity field is only numbers.")
                }

                error = true;
            }
        })

        if (error) {
            setErrorMessages(messages)
        } else {
            updateInventory({
                variables: {
                    ...inventoryForm,
                    retailPrice: parseFloat(inventoryForm['retailPrice']),
                    costPrice: parseFloat(inventoryForm['costPrice']),
                    quantity: parseInt(inventoryForm['quantity']),
                    previousQuantity: parseInt(inventoryForm['previousQuantity'])
                },
                refetchQueries: [{ query: INVENTORIES, variables: { search_term: "", order: "id_DESC" } }]
            })
        }

    }

    var data = {vendor: {}, images: []}
    if (dataInventory) {
        data = {...dataInventory.inventory}
    }

    if (deleteInventoryModalIsOpen) {
        return (
            <DeleteInventory id={data.id} setIsOpen={setIsOpen} setDeleteInventoryModal={setDeleteInventoryModal} isOpen={deleteInventoryModalIsOpen}/>
        )
    } else {
        return (
            <Modal 
                isOpen={isOpen}
                contentLabel="Update Inventory"
                onRequestClose={() => {setIsOpen(false)}}
            >
                
                <div className="page-header">
                    <div className="header-text">
                        Edit SKU #: {data.sku}
                    </div>
                    <div className="header-desc">
                        <div className="page-header-close">
                            <div onClick={() => {setIsOpen(false)}}>
                                &#10005;
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page inventory-page">
                    <div className="form-row">
                        <div className="form-title">
                            Product Name
                        </div>
                        <Input form={inventoryForm} errors={errors} name={"name"} handleInputChange={handleInputChange} />
                    </div>
                    <div className="form-row">
                        <div className="form-title">
                            Vendor Name
                        </div>
                        
                        <div className="form-desc">
                            {data.vendor.vendorName}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-title">
                            Vendor Email Address
                        </div>
                        <div className="form-desc">
                            {data.vendor.email}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-title">
                            Vendor Phone Number
                        </div>
                        <div className="form-desc">
                            {data.vendor.phone}
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-title">
                            Address
                        </div>
                        {
                            data.vendor.address1 || data.vendor.city || data.vendor.province || data.vendor.postal ? 
                                <div className="form-desc">
                                    {data.vendor.address1}, {data.vendor.city} {data.vendor.province}, {data.vendor.postal}
                                </div>
                            :
                                <div className="form-desc">
                                    
                                </div>
                        }

                    </div>
                    <div className="form-row">
                        <div className="form-title">
                            Description
                        </div>
                        <div className="form-desc" style={{width: "40%"}}>
                            <textarea 
                                name={'description'}
                                onChange={handleInputChange}
                                className={"input-box" + (errors['description'] ? " error" : "")}
                                value={inventoryForm['description']}
                            ></textarea>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-title">
                            Cost Price
                        </div>
                        <Input form={inventoryForm} errors={errors} name={"costPrice"} handleInputChange={handleInputChange}/>
                    </div>
                    <div className="form-row">
                        <div className="form-title">
                            Retail Price
                        </div>
                        <Input form={inventoryForm} errors={errors} name={"retailPrice"} handleInputChange={handleInputChange}/>
                    </div>
                    {
                        data.images.length > 0 && (
                            <div className="form-row">
                                <div className="form-title">
                                    Images
                                </div>
                                <div className="form-desc">
                                    <div className="upload-images-container ">
                                        {
                                            data.images.map((image, index) => (
                                                
                                                <div className="image-container">
                                                    <img src={image.url} className="image-square" alt={image.name}/>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className="form-row">
                        <div className="form-title">
                            Quantity
                        </div>
                        <div className="form-desc">
                            <input className={'blue-border quantityInput'} disabled value={data.quantity}/>
                            →
                            <input type="number" className={'blue-border quantityInput' + (errors['quantity'] ? " error" : "")} value={inventoryForm.quantity} name="quantity" onChange={handleInputChange}/>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-title">
                            Location
                        </div>
                        <div className="form-desc">
                            <input className={'blue-border locationInput'} disabled value={data.location}/>
                            →
                            <input className={'blue-border locationInput' + (errors['location'] ? (" error") : "")} value={inventoryForm.location} name="location" onChange={handleInputChange}/>
                        </div>
                    </div>
                    <div className="form-row barcode-row">
                        <div className="form-title">
                            Barcode
                        </div>
                        <div className="form-desc">
                            <SkuBarcode sku={data.sku} retailPrice={data.retailPrice} ref={componentRef}/>
                            <ReactToPrint
                                trigger={() => <a hraef="#" className="print-button">Print</a>}
                                content={() => componentRef.current}
                            />
                        </div>
                    </div>
                    <div className="form-row" style={{justifyContent: "space-around"}}>
                        <button className="primary button" onClick={submitInventory}>Save</button>
                        <button className="button danger" onClick={() => setDeleteInventoryModal(true)}>
                                Delete Inventory
                        </button>
                    </div>
                </div>
            </Modal>
        )
    }

}

export default EditInventory