import { gql } from '@apollo/client';

export const RESET_PASSWORD = gql`
    mutation resetPassword($oldPassword: String!, $newPassword: String!) {
        resetPassword(oldPassword: $oldPassword, newPassword: $newPassword) {
            message
        }
    }
`;

export const LOG_ERROR = gql`
    mutation logError($error: String, $errorInfo: String) {
        logError(error: $error, errorInfo: $errorInfo) {
            message
        }
    }
`;