const priorities = {
    1: {
        title: 'Low',
        color: "#0F6900"
    },
    2: {
        title: 'Medium',
        color: "#FFD700"
    },
    3: {
        title: 'High',
        color: "#AE0403"
    },
}

export default priorities;
