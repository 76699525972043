import { gql } from '@apollo/client';

export const ADD_ORDER = gql`
    mutation addOrder($customerId: String, $webOrderNumber: String, $customerName: String, $companyName: String, $customerEmail: String, $customerPhone: String, $customerAddress1: String, $customerAddress2: String, $customerCity: String, $customerProvince: String, $customerPostal: String, $orderTypes: [String]!, $primaryOrderType: String!, $description: String, $startDate: String!, $endDate: String!, $priority: Int!, $quantity: Int, $phase: Int, $amountOwed: String, $comments: String, $inventory: [OrderInventoryInput]) {
        addOrder(customerId: $customerId,  webOrderNumber: $webOrderNumber, customerName: $customerName, companyName: $companyName, customerEmail: $customerEmail, customerPhone: $customerPhone, customerAddress1: $customerAddress1, customerAddress2: $customerAddress2, customerCity: $customerCity, customerProvince: $customerProvince, customerPostal: $customerPostal, orderTypes: $orderTypes, primaryOrderType: $primaryOrderType,  description: $description, startDate: $startDate, endDate: $endDate, priority: $priority, quantity: $quantity, phase: $phase, amountOwed: $amountOwed, comments: $comments, inventory: $inventory) {
            id
            orderNumber
        }
    }
`;

export const EDIT_ORDER = gql`
    mutation editOrder($id: String!, $customerId: String, $webOrderNumber: String, $customerName: String, $companyName: String, $customerEmail: String, $customerPhone: String, $customerAddress1: String, $customerAddress2: String, $customerCity: String, $customerProvince: String, $customerPostal: String, $orderTypes: [String]!, $primaryOrderType: String!, $description: String, $startDate: String!, $endDate: String!, $priority: Int!, $quantity: Int, $phase: Int, $amountOwed: String, $comments: String, $inventory: [OrderInventoryInput]) {
        editOrder(id: $id, customerId: $customerId, webOrderNumber: $webOrderNumber,  customerName: $customerName, companyName: $companyName, customerEmail: $customerEmail, customerPhone: $customerPhone, customerAddress1: $customerAddress1, customerAddress2: $customerAddress2, customerCity: $customerCity, customerProvince: $customerProvince, customerPostal: $customerPostal, orderTypes: $orderTypes, primaryOrderType: $primaryOrderType,  description: $description, startDate: $startDate, endDate: $endDate, priority: $priority, quantity: $quantity, phase: $phase, amountOwed: $amountOwed, comments: $comments, inventory: $inventory) {
            id
            orderNumber
        }
    }
`;

export const UPDATE_ORDER = gql`
    mutation updateOrder($phase: Int!,  $images: [OrderImageInput], $orderId: String!, $previousPhase: Int!, $comments: String) {
        updateOrder(phase: $phase, images: $images, orderId: $orderId, previousPhase: $previousPhase, comments: $comments) {
            id
            orderNumber
        }
    }
`;

export const DELETE_ORDER =gql`
    mutation deleteOrder($orderId: String!) {
        deleteOrder(orderId: $orderId) {
            message
        }
    }
`;