import React, { useEffect } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import './styles/App.scss';
import { LOG_ERROR } from './mutations/user';

const ME = gql`
  query Me {
    me {
        name
        email
    }
  }
`;

function ErrBound({ error, errorInfo }) {
    const [logError, { loading: loadingLogError, error: errorLogError, data: dataLogError }] = useMutation(LOG_ERROR)
    const [logged, setLogged] = React.useState(false);

    if (! logged && error && ! dataLogError && ! errorLogError) {
        setLogged(true);
        logError({
            variables: {
                error,
                errorInfo: errorInfo.componentStack
            }
        });

    }

    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", color: "#020F5A", width: "100vw", height: "100vh"}}>
            <h1>There was an error. The admin has been notified and will track this bug in order to fix it for the future. Please refresh the page. <br />If the problem persists, email the admin at <a href="mailto:admin@kujenga.ca">admin@kujenga.ca</a></h1>  
        </div>
    )
}

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error,
            errorInfo
        })
    }

    render() {
        if (this.state.hasError) {
            return (      
                <ErrBound error={this.state.error} errorInfo={this.state.errorInfo} />
            );
        }

        return this.props.children; 
    }
}

export default ErrorBoundary;
