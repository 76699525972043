import React, { useState } from 'react';
import './login.scss';
import { useMutation, gql } from '@apollo/client';

const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
          id
          name
      }
      token
    }
  }
`;

function Login(props) {
    const [login, { loading, error }] = useMutation(LOGIN, {
        update(cache) {

        },
        onCompleted({ login }) {
            props.refetchLogin();
            localStorage.setItem('authorization', login.token);
        },
        onError({ error }) {
            setErrorMessage("Uh oh. Your username password combination was incorrect. Please try again!")
            setErrors({email: true, password: true, login: true})
        }
    });
    
    const [loginForm, setLoginForm] = useState({email: "", password: ""})
    const [forgotPassword, setForgotPassword] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [errors, setErrors] = useState({})
    
    const handleChange = e => {
        setLoginForm({
            ...loginForm,
            [e.target.name]: e.target.value
        })

        setErrors({
            ...errors,
            [e.target.name]: false
        })

        setErrorMessage("")
    }

    const submitLogin = async (e) => {
        if (e) {
            e.preventDefault();
        }
        
        try {
            if (loginForm.email && loginForm.password) {
                await login({variables: loginForm})
            }

            if (! loginForm.email) {
                setErrors({
                    ...errors,
                    email: true
                })

                setErrorMessage("Please ensure all fields are filled");
            }

            if (! loginForm.password) {
                if (errorMessage == "") {
                    setErrorMessage("Please ensure all fields are filled");
                }

                setErrors({
                    ...errors,
                    password: true
                })
            }
        } catch (err) {
          console.error(err);
        }

    }

    const submitForgotPassword = async (e) => {
    }

    const handleKeyDown = (e, func) => {
        if (e.key === 'Enter') {
            func();
        }
    }

    return (      
        <form className="login-box" onSubmit={() => { forgotPassword ? submitForgotPassword() : submitLogin() }}> 
            {
                loading || props.loadingLogin && (
                    <div className="login-mask">
                        Loading...
                    </div>
                )
            }
            <img src={require("../../images/Visort.png")} className="login-logo" alt="VisortLogo" title="VisortLogo"/>
            {
                forgotPassword ?
                    <>
                        <div className="forgot-header-text">
                            Forgot your password?
                        </div>
                        <div className="forgot-desc-text">
                            Enter the email address associated with your account to receive password reset intructions
                        </div>
                        <input className={"login-input" + (error ? " error" : "")} name="email" type="email" value={loginForm.email} onChange={handleChange} placeholder="Email Address"/>
                        <div className="login-options-container">
                            <div className="login-forgot-text" onClick={() => setForgotPassword(false)}>
                                Return to Login
                            </div>
                        </div>
                        <div className="login-button" onClick={submitForgotPassword}>Send</div>
                    </>
                :
                    <>
                        <input className={"login-input" + (errors.email ? " error" : "")} name="email" type="email" value={loginForm.email} onChange={handleChange} onKeyDown={(e) => handleKeyDown(e, () => submitLogin())} placeholder="Email Address"/>
                        <input className={"login-input" + (errors.password ? " error" : "")} name="password" type="password" value={loginForm.password} onChange={handleChange} onKeyDown={(e) => handleKeyDown(e, () => submitLogin())} placeholder="Password"/>
                        <div className="login-options-container">
                            <div className="login-forgot-text" onClick={() => setForgotPassword(true)}>
                                Forgot your password?
                            </div>
                        </div>
                        <div className="login-button" onClick={submitLogin} >Submit</div>
                    </>
            }
            <div className="error-message center" >{errorMessage}</div>
        </form>
    );
}

export default Login;
