import { gql } from '@apollo/client';

export const INVENTORIES = gql`
    query inventories($search_term: String!, $order: String!, $skip: Int) {
        inventories(search_term: $search_term, order: $order, skip: $skip) {
            inventoryCount
            inventories {
                id
                name
                sku
                images{
                    url
                }
                quantity
                description
                vendor{
                    vendorName
                    phone
                    email
                }
                location
                retailPrice
                costPrice
            }

        }
    }
`;

export const INVENTORY = gql`
    query inventory($inventoryId: String!) {
        inventory(inventoryId: $inventoryId) {
            sku
            id
            name
            images {
                url
            }
            quantity
            location
            description
            vendor {
                vendorName
                phone
                email
                address1
                address2
                city
                province
                postal
            }
            retailPrice
            costPrice
        }
    }
`;