import { gql } from '@apollo/client';

export const CUSTOMERS = gql`
    query customers($customerName: String!, $companyName: String!, $order: String!) {
        customers(customerName: $customerName, companyName: $companyName, order: $order) {
            id
            customerName
            companyName
        }
    }
`;

export const CUSTOMER = gql`
    query customer($customerId: String!) {
        customer(customerId: $customerId) {
            id
            customerName
            companyName
            phone
            email
            address1
            address2
            city
            province
            postal
        }
    }
`;