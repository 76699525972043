import { gql } from '@apollo/client';

export const ORDERS = gql`
    query orders($search_term: String!, $order: String!, $startDate: String, $endDate: String, $types: String) {
        orders(search_term: $search_term, order: $order, startDate: $startDate, endDate: $endDate, types: $types) {
            id
            orderNumber
            webOrderNumber
            startDate
            endDate
            createdAt
            quantity
            priority
            primaryOrderType {
                recommendedNumDays
                phases {
                    name
                    colour
                    phase
                    percentage
                }
            }
            orderType {
                id
                name
                prefix
                recommendedNumDays
            }
            phase {
                id
                phase
                name
                percentage
                colour
            }
            amountOwed
            customer {
                customerName
                companyName
            }
            logs {
                previousPhase {
                    phase
                }
                updatedPhase {
                    phase
                }
                createdAt
            }
            status
        }
    }
`;

export const ORDER = gql`
    query order($orderId: String!) {
        order(orderId: $orderId) {
            id
            orderNumber
            webOrderNumber
            primaryOrderType {
                id
                name
                recommendedNumDays
            }
            orderType {
                id
                name
                recommendedNumDays
            }
            startDate
            endDate
            createdAt
            description
            quantity
            priority
            phase {
                id
                name
                phase
            }
            images {
                url
                name
            }
            logs {
                editor {
                    name
                }
                images {
                    url
                }
                comments
                previousPhase {
                    id
                    phase
                    colour
                }
                updatedPhase {
                    id
                    name
                    percentage
                    phase
                    colour
                }
                action
                createdAt
            }
            customer {
                id
                companyName
                customerName
                email
                phone
                address1
                address2
                city
                province
                postal
            }
            orderInventory {
                quantity
                beforeOrderQuantity
                inventory {
                    id
                    sku
                    name
                    description
                    vendor {
                        vendorName
                        email
                    }
                    images {
                        url
                    }
                    location
                    costPrice
                    retailPrice
                }
            }
            status
        }
    }
`;

export const ORDER_TYPES = gql`
    query orderTypes{
        orderTypes {
            id
            name
            prefix
            recommendedNumDays
        }
    }
`;