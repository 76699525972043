
import React, { useState } from 'react'
import Modal from 'react-modal';
import { useMutation } from '@apollo/client';
import { DELETE_INVENTORY, UPLOAD_INVENTORY } from '../../mutations/inventory';
import { INVENTORIES } from '../../queries/Inventory';
import Dropzone from 'react-dropzone'
import { toast } from 'react-toastify';
 
function UploadInventory(props) {
    const { isOpen, setIsOpen } = props;

    Modal.setAppElement('#root');

    const [uploadInventory, { loading: loadingUpload, error: errorUpload, data: data }] = useMutation(UPLOAD_INVENTORY, {
        onCompleted:() => {
            setIsOpen(false);
            toast.success("Successfully Uploaded Inventory File. Please wait a few minutes before editing any inventory items");
        },
        onError:() => {
            toast.error("There was an error in uploading your inventory file. Please try again.");
        }
    });

    const [inventoryFile, setInventoryFile] = useState(null);

    const uploadInventoryFile = acceptedFiles => {
        if (acceptedFiles.length > 0) setInventoryFile(acceptedFiles[0]);
    }

    return (
        <Modal 
            isOpen={isOpen}
            contentLabel="Upload Inventory CSV"
            onRequestClose={() => {setIsOpen(false)}}
            className="delete-modal-overlay upload"
        >
            <div className="delete-modal">
                <div className="confirm-delete">Upload your inventory file below</div>
                <div className="desc-delete">Uploading the inventory may take a few minutes to complete.</div>
                <Dropzone 
                    onDrop={acceptedFiles => {
                        uploadInventoryFile(acceptedFiles)
                    }}
                    accept={".csv"}
                >
                    {({getRootProps, getInputProps}) => (
                        <div className="dropzone-container">
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <img src={require("../../images/EmptyImage.png")} className="EmptyImage" alt="EmpyImage" title="EmptyImage"/>
                                <div className="drop-image-text">Drop your CSV here, or browse</div>
                                <div className="drop-image-support">Supports CSV only</div>
                            </div>
                        </div>
                    )}
                </Dropzone>
                    {
                        inventoryFile && (
                            <div style={{margin: "10px auto"}}>
                                Current File: {inventoryFile.name}
                            </div>
                        )
                    }
                <div className={"button primary success" + (inventoryFile ? "" : " disabled")} onClick={async () => {
                    const inventoryData = await inventoryFile.text();
                    if (inventoryFile) uploadInventory({
                        variables: {
                            csvFile: inventoryData
                        }
                    })
                }}>Upload Inventory</div>
                <div className="cancel-delete" onClick={() => setIsOpen(false)}>Cancel</div>
            </div>
        </Modal>
    )
}

export default UploadInventory