
import React from 'react'
import moment from 'moment'
import businessMoment from 'moment-business-days'
import phases from '../../utils/phases'

import "./styles.scss";
import ReactToPrint from 'react-to-print';

class ViewOrderContent extends React.Component {
    renderPhase = (logs) => {
        var orders = [];

        var last_phase = 1;

        logs.map((log) => {
            if (log.updatedPhase.phase >= last_phase) {
                last_phase = log.updatedPhase.phase + 1;
            }
            
            orders.push(
                <div className="order-phase-row">
                    <div className="order-phase-number" style={{backgroundColor: log.updatedPhase.colour}}>
                        <i class="fa fa-check" aria-hidden="true"></i>
                    </div>
                    <div className="order-phase-info">
                        <div className="order-phase-title">
                            {log.updatedPhase.name}
                        </div>
                        <div className="order-phase-info">
                            <span style={{fontWeight: "bold"}}>{log.editor.name}</span>  <span>{phases[log.updatedPhase.phase].desc}</span>
                        </div>
                        <div className="order-phase-date">
                            <i class="fa fa-clock-o" aria-hidden="true"></i> {moment(log.createdAt).fromNow()}
                        </div>
                    </div>
                </div>
            )
        })

        while (last_phase <= 5) {
            orders.push(
                <div className="order-phase-row">
                    <div className="order-phase-number" style={{backgroundColor: phases[last_phase].colour}}>
                        {last_phase}
                    </div>
                    <div className="order-phase-info">
                    </div>
                </div>
            )

            last_phase++;
        }

        return orders;
    }

    renderComments = (logs) => {
        var comments = []
        var revlogs = []

        logs.map((log) => {
            revlogs.push(log);
        })

        revlogs = revlogs.reverse();

        revlogs.map((log) => {
            comments.push(
                <div className="comment-container">
                    <div className="comment-header">
                        <div className="comment-name-initials">
                            {log.editor.name.split(" ")[0][0]}{log.editor.name.split(" ")[0][1]}
                        </div>
                        <div className="comment-name-main">
                            <div className="comment-name">
                                {log.editor.name}
                            </div>
                            <div className="comment-date">
                                {moment(log.createdAt).format("YYYY-DD-MM, h:mm a")}
                            </div>
                        </div>
                    </div>
                    <div className="comment-main">
                        {log.comments}
                    </div>
                </div>
            )
        })

        return comments
    }

    calcNumDays = () => {
        var num_days = 0;

        if (this.props.data.startDate && this.props.data.endDate) {
            num_days = businessMoment(this.props.data.startDate).businessDiff(businessMoment(this.props.data.endDate));
        }

        return num_days;
    }

    render() {
        return (
            <div className="modal view-order-modal">
                <div className="page-header">
                    <div className="header-text">
                        Order # {this.props.data.orderNumber}
                    </div>
                    <div className="header-desc">
                        <div className="page-header-desc">
                            {
                                this.props.data.status === "ACTIVE" ? 
                                    <div className="days-left">
                                        {this.calcNumDays()} <br />
                                        Days
                                    </div>
                                :
                                    <div className="cancelled">
                                        CANCELLED
                                    </div>
                            }

                            <div className="order-days-breakdown">
                                <div>
                                    <div className="order-days-text">Target Start Date</div>
                                    <div className="order-days-date">{moment(this.props.data.startDate).format("MMM D, YYYY")}</div>
                                </div>
                                <div className={this.props.data.status === "ACTIVE" ? "order-days-highlighted" : ""}>
                                    <div className="order-days-text">Delivery Date</div>
                                    <div className="order-days-date">{moment(this.props.data.endDate).format("MMM D, YYYY")}</div>
                                </div>
                            </div>
                        </div>
                        <div className="page-header-close">
                            <div onClick={() => {this.props.setIsOpen(false)}}>
                                &#10005;
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page">
                    <div className="view-box">
                        <div className="box-header">
                            Order Information
                        </div>
                        <div className="box-contents">
                            <div className="box-details">
                                <div className="details-header">
                                    Web Order #
                                </div>
                                <div className="details-info">
                                    {this.props.data.webOrderNumber}
                                </div>
                            </div>
                            <div className="box-details">
                                <div className="details-header">
                                    Company Name
                                </div>
                                <div className="details-info">
                                    {this.props.data.customer.companyName}
                                </div>
                            </div>
                            <div className="box-details">
                                <div className="details-header">
                                    Customer Email Address
                                </div>
                                <div className="details-info">
                                    {this.props.data.customer.email}
                                </div>
                            </div>
                            <div className="box-details">
                                <div className="details-header">
                                    Customer Phone Number
                                </div>
                                <div className="details-info">
                                    {this.props.data.customer.phone}
                                </div>
                            </div>
                            <div className="box-details">
                                <div className="details-header">
                                    Address
                                </div>
                                <div className="details-info">
                                    {this.props.data.customer.address1}, {this.props.data.customer.city} {this.props.data.customer.province}, {this.props.data.customer.postal}
                                </div>
                            </div>
                            <div className="box-details">
                                <div className="details-header">
                                    Order Types
                                </div>
                                <div className="details-info">
                                    {this.props.data.orderType.map((type, index) => (
                                        <span>{index > 0 && (", ")}{type.name}</span>
                                    ))}
                                </div>
                            </div>
                            <div className="box-details">
                                <div className="details-header">
                                    Description
                                </div>
                                <div className="details-info">
                                    {this.props.data.description}
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.props.data.images.length > 0 && (
                            <div className="view-box">
                                <div className="box-header">
                                    Images
                                </div>
                                <div className="box-contents">
                                    <div className="box-details image-rows-container">
                                        {
                                            this.props.data.images.map((image) => (
                                                <a className="image-row" href={image.url} target="_blank"> {image.name || image.url.split("/")[image.url.split("/").length - 1]} </a>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className="view-box">
                        <div className="box-header">
                            Selected Inventory
                        </div>
                        <div className="box-contents">
                            <div className="box-details inventory-container">
                                <div className="order-inventory-headers">
                                    <div style={{width: "10%"}}>Quantity</div>
                                    <div style={{width: "25%"}}>Item</div>
                                    <div style={{width: "25%"}}>Desc</div>
                                    <div style={{width: "20%"}}>SKU</div>
                                    <div style={{width: "10%"}}>B/O</div>
                                    <div style={{width: "10%"}}>Retail Price</div>
                                    <div style={{width: "25%"}}>Vendor</div>
                                </div>
                                {
                                    this.props.data.orderInventory.map((ordInv) => {
                                        var backOrder = ordInv.beforeOrderQuantity - ordInv.quantity < 0 ? Math.abs(ordInv.beforeOrderQuantity - ordInv.quantity) : 0;

                                        return (
                                            <div className="order-inventory-row" style={backOrder > 0 ? {color: 'red'} : {color: 'black'} } key={ordInv.id}>
                                                <div style={{width: "10%"}}>{ordInv.quantity}</div>
                                                <div style={{width: "25%"}}>{ordInv.inventory.name}</div>
                                                <div style={{width: "25%"}}>{ordInv.inventory.description}</div>
                                                <div style={{width: "20%"}}>SKU # {ordInv.inventory.sku}</div>
                                                <div style={{width: "10%"}}>{backOrder}</div>
                                                <div style={{width: "10%"}}>{ordInv.inventory.retailPrice}</div>
                                                <div style={{width: "25%"}}>{ordInv.inventory.vendor.email}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="view-box order-log">
                            {this.renderPhase(this.props.data.logs)}
                            <div className="phase-through-line" />
                        </div>
                        <div className="view-box comments-log">
                            {this.renderComments(this.props.data.logs)}
                        </div>
                    </div>
                    <div className="flex buttons-row" style={{marginTop: '20px'}}>
                        {
                            this.props.data.status === "ACTIVE" && (
                                <div className="button primary large" onClick={() => {
                                    this.props.setIsOpen(false);
                                    this.props.setEditPhaseOpen(true);
                                }}>
                                    Edit Phase
                                </div>
                            )
                        }

                        {
                            this.props.data.status === "ACTIVE" && (
                                <div className="button primary large" onClick={() => this.props.editOrder(true)}>
                                    Edit Order
                                </div>
                            )
                        }
                        <ReactToPrint
                            trigger={() => (
                                <div className="button primary large">
                                    Print Order
                                </div>
                            )}
                            content={() => this.props.printRef.current}
                        />
                        {
                            this.props.data.status === "ACTIVE" && (
                                <div className="button danger large" onClick={() => {this.props.deleteOrder(true)}}>
                                    Delete Order
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }

}

export default ViewOrderContent