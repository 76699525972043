
import React, { useState, useRef } from 'react'
import Modal from 'react-modal';
import { useQuery } from '@apollo/client';
import { ORDER } from '../../queries/Orders';

import "./styles.scss";
import ViewOrderContent from './ViewOrderContent';
import DeleteOrder from './DeleteOrder';
import EditOrder from './EditOrder';
import PrintOrder from './PrintOrder';
 

function ViewOrder(props) {
    const { isOpen, setIsOpen, id, setEditPhaseOpen } = props;

    const [deleteOrderModalIsOpen, setDeleteOrderModal] = React.useState(false);
    const [editOrderModalIsOpen, setEditOrderModal] = React.useState(false);

    const componentRef = useRef();

    Modal.setAppElement('#root');

    const { data: dataOrder } = useQuery(ORDER, {
        variables: { orderId: props.id },
    });

    var data = {customer: {}, orderType: [], images: [], orderInventory: [], logs: []}
    if(dataOrder) {
        data = {...dataOrder.order}
    }

    if (deleteOrderModalIsOpen) {
        return (
            <DeleteOrder id={data.id} setIsOpen={setIsOpen} setDeleteOrderModal={setDeleteOrderModal} isOpen={deleteOrderModalIsOpen} order={props.order}/>
        )
    }

    if (editOrderModalIsOpen) {
        return (
            <EditOrder id={data.id} setIsOpen={setIsOpen} setEditOrderModal={setEditOrderModal} isOpen={editOrderModalIsOpen} order={props.order}/>
        )
    }

    return (
        <Modal 
            isOpen={isOpen}
            contentLabel="Add New Inventory Form"
            onRequestClose={() => {setIsOpen(false)}}
        >
            <ViewOrderContent data={data} printRef={componentRef}  setIsOpen={setIsOpen} isOpen={isOpen} setEditPhaseOpen={setEditPhaseOpen} deleteOrder={setDeleteOrderModal} editOrder={setEditOrderModal}/>
            <PrintOrder ref={componentRef} data={data}/>
        </Modal>
    )

}

export default ViewOrder