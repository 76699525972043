
import React, { useState, useRef } from 'react'
import Modal from 'react-modal';
import moment from 'moment'
import { useQuery, useMutation, onError } from '@apollo/client';
import { ORDER, ORDERS } from '../../queries/Orders';

import "./styles.scss";
import ReactToPrint from 'react-to-print';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { UPDATE_ORDER } from '../../mutations/order';
import { toast } from 'react-toastify';
import phases from '../../utils/phases';
 
function EditPhase(props) {
    const { isOpen, setIsOpen, setViewOrderOpen, id } = props;

    const default_form = {
        images: [],
        comments: "",
        previousPhase: 1,
        phase: 2,
        orderId: ""
    }

    const [orderForm, setOrderForm] = useState({
       ...default_form 
    });

    Modal.setAppElement('#root');

    const { loading: loadingOrder, error: errorOrder, data: dataOrder } = useQuery(ORDER, {
        variables: { orderId: id },
    });

    const [updateOrder, { loading: loadingUpdateOrder, error: errorUpdateOrder, data: dataUpdateOrder }] = useMutation(UPDATE_ORDER, {
        onCompleted: () => {
            setIsOpen(false);
            setViewOrderOpen(true);
            toast.success("Successfully Updated Order!");
        },
        onError: (error) => {
            toast.error(error.message);
        }
    })


    if(!loadingOrder && !errorOrder && orderForm.orderId !== dataOrder.order.id) {
        var images = []
        if (dataOrder.order.phase > 1) {
            images = dataOrder.order.images
        }

        setOrderForm({
            images,
            comments: "",
            previousPhase: parseInt(dataOrder.order.phase.phase),
            phase: parseInt(dataOrder.order.phase.phase) + 1,
            orderId: dataOrder.order.id
        })
    }

    var data = {customer: {}, orderType: [], images: [], orderInventory: [], logs: [], phase: {}}
    if(dataOrder) {
        data = {...dataOrder.order}
    }

    const renderPhase = (logs) => {
        var orders = [];

        var last_phase = 1;

        logs.map((log) => {
            if (log.updatedPhase.phase >= last_phase) {
                last_phase = log.updatedPhase.phase + 1;
            }
            
            orders.push(
                <div className="order-phase-row">
                    <div className="order-phase-number" style={{backgroundColor: log.updatedPhase.colour}}>
                        <i class="fa fa-check" aria-hidden="true"></i>
                    </div>
                    <div className="order-phase-info">
                        <div className="order-phase-title">
                            {log.updatedPhase.name}
                        </div>
                        <div className="order-phase-info">
                            <span style={{fontWeight: "bold"}}>{log.editor.name}</span>  <span>{phases[log.updatedPhase.phase].desc}</span>
                        </div>
                        <div className="order-phase-date">
                            <i class="fa fa-clock-o" aria-hidden="true"></i> {moment(log.createdAt).fromNow()}
                        </div>
                    </div>
                </div>
            )
        })

        while (last_phase <= 5) {
            orders.push(
                <div className="order-phase-row">
                    <div className="order-phase-number" style={{backgroundColor: phases[last_phase].colour}}>
                        {last_phase}
                    </div>
                    <div className="order-phase-info">
                    </div>
                </div>
            )

            last_phase++;
        }

        return orders;
    }

    const renderComments = (logs) =>{
        var comments = []
        var revlogs = []

        logs.map((log) => {
            revlogs.push(log);
        })

        revlogs = revlogs.reverse();

        revlogs.map((log) => {
            comments.push(
                <div className="comment-container">
                    <div className="comment-header">
                        <div className="comment-name-initials">
                            {log.editor.name.split(" ")[0][0]}{log.editor.name.split(" ")[0][1]}
                        </div>
                        <div className="comment-name-main">
                            <div className="comment-name">
                                {log.editor.name}
                            </div>
                            <div className="comment-date">
                                {moment(log.createdAt).format("YYYY-DD-MM, h:mm a")}
                            </div>
                        </div>
                    </div>
                    <div className="comment-main">
                        {log.comments}
                    </div>
                </div>
            )
        })

        return comments
    }

    const calcNumDays = () => {
        return 15;
    }
    const uploadImages = (acceptedFiles) => {
        acceptedFiles.map((image, index) => {
            var images = orderForm.images
            image.uploading = true;
            var image_index = images.length;
            images.push(image);

            setOrderForm({
                ...orderForm,
                images
            })

            var url = `https://api.cloudinary.com/v1_1/abztest/upload`;
            const formData = new FormData();
            formData.append("file", image);
            formData.append("api_key", '364725172583177');
            formData.append("upload_preset", "ecgold"); 
            formData.append("folder", "ecgold"); 
            formData.append("timestamp", (Date.now() / 1000) | 0);
            axios.post(url, formData, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest"
                }
            })
            .then(response => {
                images = orderForm.images;
                images.splice(image_index, 1, {url: response.data.url, name: image.name, uploading: false});
                setOrderForm({
                    ...orderForm,
                    images
                })
            })
            .catch(err => {
                console.log("There has been ERROR: " + err)

                images = orderForm.images;
                images = images.splice(image_index, 1);

                setOrderForm({
                    ...orderForm,
                    images
                })
            });
        })
    }

    const submitPhaseUpdate = () => {
        var images = []
        orderForm.images.map((image) => {
            images.push({url: image.url, name: image.name});
        });

        updateOrder({
            variables: {
                ...orderForm,
                images
            },
            refetchQueries: [{ query: ORDER, variables: { orderId: orderForm.orderId } }, { query: ORDERS, variables: { search_term: "", order: props.order, startDate: props.startDate, endDate: props.endDate } }]
        })
    }

    return (
        <Modal 
            isOpen={isOpen}
            contentLabel="Add New Inventory Form"
            onRequestClose={() => {setIsOpen(false)}}
        >
            <div className="page-header">
                    <div className="header-text">
                        Order # {data.orderNumber}
                    </div>
                    <div className="header-desc">
                        <div className="page-header-desc">
                            <div className="days-left">
                                {calcNumDays()} <br />
                                Days
                            </div>
                            <div className="order-days-breakdown">
                                <div>
                                    <div className="order-days-text">Target Start Date</div>
                                    <div className="order-days-date">{moment(data.startDate).format("MMM D, YYYY")}</div>
                                </div>
                                <div className="order-days-highlighted">
                                    <div className="order-days-text">Delivery Date</div>
                                    <div className="order-days-date">{moment(data.endDate).format("MMM D, YYYY")}</div>
                                </div>
                            </div>
                        </div>
                        <div className="page-header-close">
                            <div onClick={() => {setIsOpen(false)}}>
                                &#10005;
                            </div>
                        </div>
                    </div>
                </div>
            <div className="page">
                <div className="flex">
                    <div className="view-box order-log">
                        {renderPhase(data.logs)}
                        <div className="phase-through-line" />
                    </div>
                    <div className="view-box comments-log">
                        <textarea 
                            className="comments-log-input" 
                            value={orderForm.comments}
                            name="comments"
                            placeholder="Add a new comment..."
                            onChange={(e) => {
                                var value = e.target.value;
                        
                                setOrderForm({
                                    ...orderForm,
                                    comments: value
                                })
                            }}
                            rows="3"
                            />
                        {renderComments(data.logs)}
                    </div>
                </div>
                <div className="view-box">
                    <div className="box-header">
                        Order Information
                    </div>
                    <div className="box-contents">
                        <div className="box-details">
                            <div className="details-header">
                                Company Name
                            </div>
                            <div className="details-info">
                                {data.customer.customerName}
                            </div>
                        </div>
                        <div className="box-details">
                            <div className="details-header">
                                Customer Email Address
                            </div>
                            <div className="details-info">
                                {data.customer.email}
                            </div>
                        </div>
                        <div className="box-details">
                            <div className="details-header">
                                Customer Phone Number
                            </div>
                            <div className="details-info">
                                {data.customer.phone}
                            </div>
                        </div>
                        <div className="box-details">
                            <div className="details-header">
                                Address
                            </div>
                            <div className="details-info">
                                {data.customer.address1}, {data.customer.city} {data.customer.province}, {data.customer.postal}
                            </div>
                        </div>
                        <div className="box-details">
                            <div className="details-header">
                                Order Types
                            </div>
                            <div className="details-info">
                                {data.orderType.map((type, index) => (
                                    <span>{index > 0 && (", ")}{type.name}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="view-box">
                    <div className="box-header">
                        Phase 2 Photo Upload
                    </div>
                    <div className="box-contents">
                        {
                            data.phase.phase === 1 ? 
                                <div className="box-contents">
                                    <Dropzone onDrop={acceptedFiles => {
                                        uploadImages(acceptedFiles)
                                    }}>
                                        {({getRootProps, getInputProps}) => (
                                            <div className="dropzone-container">
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <img src={require("../../images/EmptyImage.png")} className="EmptyImage" alt="EmpyImage" title="EmptyImage"/>
                                                    <div className="drop-image-text">Drop your file here, or browse</div>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                    <div className="box-details image-rows-container">
                                        {
                                            orderForm.images.map((image, index) => (
                                                image.uploading ?
                                                    <div>
                                                        <div>Uploading...</div>
                                                        <div>{image.url}</div>
                                                    </div>
                                                :
                                                <div className="image-row">
                                                    <a className="image-name" href={image.url} target="_blank" >{image.name}</a>
                                                    <div className="image-row-delete" onClick={() => {
                                                        var images = orderForm.images;
                                                        images.splice(index, 1);
                                                        setOrderForm({
                                                            ...orderForm,
                                                            images
                                                        })
                                                    }}>&#10005;</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            :
                                <div className="box-details image-container">
                                    {
                                        data.images.map((image, index) => (
                                            <div className="image-container">
                                                 <img id={image.id} src={image.url} className="image-square" alt={image.id}/>
                                            </div>
                                        ))
                                    }
                                </div>
                        }
                    </div>
                </div>
                <div className="view-box">
                    <div className="box-header">
                        Selected Inventory
                    </div>
                    <div className="box-contents">
                        <div className="box-details inventory-container">
                                <div className="order-inventory-headers">
                                    <div style={{width: "10%"}}>Quantity</div>
                                    <div style={{width: "30%"}}>Item</div>
                                    <div style={{width: "20%"}}>SKU</div>
                                    <div style={{width: "20%"}}>B/O</div>
                                    <div style={{width: "20%"}}>Retail Price</div>
                                </div>
                                {
                                    data.orderInventory.map((ordInv) => (
                                        <div className="order-inventory-row" key={ordInv.id}>
                                            <div style={{width: "10%"}}>{ordInv.quantity}</div>
                                            <div style={{width: "30%"}}>{ordInv.inventory.name}</div>
                                            <div style={{width: "20%"}}>SKU # {ordInv.inventory.sku}</div>
                                            <div style={{width: "20%"}}>{ordInv.beforOrderQuantity}</div>
                                            <div style={{width: "20%"}}>{ordInv.inventory.retailPrice}</div>
                                        </div>
                                    ))
                                }
                            </div>
                    </div>
                </div>
                <div className="flex justify">
                    <div className="button primary large" onClick={() => {
                        submitPhaseUpdate()
                    }}>
                        Submit Order
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default EditPhase